import React, {useEffect, useState} from "react";


import {Col, Row} from "react-bootstrap";

import {NavLink} from "react-router-dom";


import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";


import noAvatarImage from "@Assets/Images/no-avatar.jpg";
import REST_API_URLS from "@API/URLS";
const {restApiRootURL} = REST_API_URLS;

export const StatisticsTablePlayerComponent = ({playerData = null, statisticsType, selectedStatisticsType = "TPTS"}) => {
	
	
	const {
		player_id: playerID, player_team: playerTeamID, tablePosition, player_games: playerGames
	} = playerData;
	
	
	const REST_API_URL = process.env.REACT_APP_REST_API_URL;
	
	const API_PLAYER_DATA_URL = `${restApiRootURL}/teams/players/${playerID}/`;
	const API_TEAM_DATA_URL = `${restApiRootURL}/teams/teams/${playerTeamID}/`;
	
	
	const [PLAYER_DATA, SET_PLAYER_DATA] = useState(null);
	const [TEAM_DATA, SET_TEAM_DATA] = useState(null);
	
	
	const GET_PLAYER_DATA = async () => {
		try {
			const playerResponse = await fetch(API_PLAYER_DATA_URL);
			const teamResponse = await fetch(API_TEAM_DATA_URL);
			SET_PLAYER_DATA(await playerResponse.json());
			SET_TEAM_DATA(await teamResponse.json());
		} catch (e) {
			console.log("REQUEST ERROR!");
		}
	};
	
	useEffect(() => {
		GET_PLAYER_DATA()
	}, [selectedStatisticsType]);
	
	
	const {
		player_first_name: playerFirstName, player_last_name: playerLastName, player_slug: playerSlug,
		player_profile_image: playerProfileImage
	} = PLAYER_DATA || {};
	
	const {team_name: teamName, team_slug: teamSlug} = TEAM_DATA || {};
	
	
	let selectedStatisticsTypeLabel;
	
	switch (selectedStatisticsType) {
		case "TPTS":
			selectedStatisticsTypeLabel = "Punkty";
			break;
		case "EVAL":
			selectedStatisticsTypeLabel = "EVAL";
			break;
		case "AST":
			selectedStatisticsTypeLabel = "Asysty";
			break;
		case "TREB":
			selectedStatisticsTypeLabel = "Zbiórki";
			break;
		case "STL":
			selectedStatisticsTypeLabel = "Przechwyty";
			break;
		case "BLK":
			selectedStatisticsTypeLabel = "Bloki";
			break;
		default:
			selectedStatisticsTypeLabel = "Punkty";
	}
	
	
	const selectedStatisticsTypeValue = statisticsType === 1 ?
		(playerData[selectedStatisticsType] / playerGames).toFixed(1) : playerData[selectedStatisticsType]
	;
	
	
	return (
		<Row className={"statistics-table-player"}>
		
			<Col xs={1} className={"table-player-position"}>
				<span>{tablePosition}</span>
			</Col>
			
			<Col xs={2} className={"table-player-avatar"}>
				{PLAYER_DATA ?
				<NavLink
					to={`/zawodnicy/${playerSlug}`}
					title={`${playerFirstName} ${playerLastName}`}
					onClick={e => e.preventDefault()}
					className={"table-player-avatar-link"}
				>
					<img src={playerProfileImage || noAvatarImage} alt={``}/>
				</NavLink>
					:
				<SingleContentLoaderComponent />
				}
			</Col>
			
			<Col xs={5} lg={6} className={"table-player-information"}>
				<Row className={"table-player-name"}>
					<Col xs={12} className={"table-player-name-value"}>
						{PLAYER_DATA ?
							<NavLink to={`/zawodnicy/${playerSlug}`} title={`${playerFirstName} ${playerLastName}`}>
								<span>{`${playerFirstName} ${playerLastName}`}</span>
							</NavLink>
							:
							<SingleContentLoaderComponent />
						}
					</Col>
				</Row>
				<Row className={"table-player-team"}>
					<Col xs={12} className={"table-player-team-name"}>
						{TEAM_DATA ?
							<NavLink to={`/druzyny/${teamSlug}`} title={teamName}>
								<span>{teamName}</span>
							</NavLink>
							:
							<SingleContentLoaderComponent />
						}
					</Col>
				</Row>
			</Col>
			
			<Col xs={4} lg={3} className={"table-player-data-value"}>
				<span className={"data-value"}>{selectedStatisticsTypeValue}</span>
				
				<span className={"data-label"}>{selectedStatisticsTypeLabel} {statisticsType === 1 ? " / mecz" : ""}</span>
				
			</Col>
		
		</Row>
	);
	
};


export default StatisticsTablePlayerComponent;
