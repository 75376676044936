import React from "react";

import {GLOBALS} from "@Globals";


import {NavLink} from "react-router-dom";

import {Col, Row} from "react-bootstrap";


import ContainerSectionComponent from "@Elements/Containers/MainSection";


import noAvatarImage from "@Images/no-avatar.jpg";


export const PlayerMainDataComponent = ({playerData}) => {
	
	
	if (!playerData) return null;
	
	
	const {
		player_first_name: playerFirstName, player_last_name: playerLastName, player_nick_name: playerNickName,
		player_profile_image: playerProfileImage,
		player_position: playerFieldPosition, player_number: playerFieldNumber,
		player_team: {
			team_city: playerTeamCity, team_logo: playerTeamLogo, team_name: playerTeamName, team_slug: playerTeamSlug
		}
	} = playerData;
	
	
	// const playerFullName = `${playerFirstName} ${playerLastName}`;
	
	
	const POSITIONS = GLOBALS.GAMES.FIELD_POSITIONS;
	
	
	return (
		<ContainerSectionComponent id={"player-details-main-data-component"}>
		
			<Col xs={12} className={"player-main-data-component"}>
				<Row className={"main-data-component-content"}>
					
					<Col xs={12} lg={2} className={"player-team-logo"}>
						<NavLink to={`/druzyny/${playerTeamSlug}`} title={playerTeamName}>
							<img src={playerTeamLogo || noAvatarImage} alt={""}/>
						</NavLink>
					</Col>
					
					<Col xs={12} lg={10} className={"player-main-data"}>
						<Row className={"main-data-content"}>
							
							<Col xs={4} lg={3} className={"player-avatar"}>
								<Row as={"picture"} className={"player-avatar-picture"}>
									<img src={playerProfileImage || noAvatarImage} alt={""} />
								</Row>
							</Col>
							
							<Col xs={8} lg={9} className={"player-information"}>
								
								<Row className={"player-field-information"}>
									<Col xs={12} className={"player-information"}>
										<NavLink to={`/druzyny/${playerTeamSlug}`} title={playerTeamName} className={"player-team-name"}>
											<span>{playerTeamName} {playerTeamCity}</span>
										</NavLink>
										<span className={"player-field-position"}>{POSITIONS[playerFieldPosition]}</span>
										<span className={"player-field-number"}>
											<span>Numer</span>
											<span className={"field-number"}>{playerFieldNumber || "bd"}</span>
										</span>
									</Col>
								</Row>
								
								<Row className={"player-name"}>
									<Col xs={12} className={"player-full-name"}>
										<span>{playerFirstName}</span>
										{!!playerNickName && <span>"{playerNickName}"</span>}
										<span>{playerLastName}</span>
									</Col>
								</Row>
								
							</Col>
							
						</Row>
					</Col>
					
				</Row>
			</Col>
		
		</ContainerSectionComponent>
	);
	
};


export default PlayerMainDataComponent;
