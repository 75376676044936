import React from "react";

import {Animate} from 'react-move';


import {Col} from "react-bootstrap";


import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";


export const ProgressBarStatisticComponent = ({statisticLabel, statisticValue}) => {
	
	
	return (
		<Col xs={4} lg={{span: 3, offset: 1}} className={"player-statistics-progressbar"}>
			<CircularProgressbar
				value={statisticValue || 0} maxValue={100} text={`${statisticValue || 0}%`}
				strokeWidth={10}
				background
				styles={buildStyles({
					textColor: "#FFF",
					pathColor: "#E2000F",
					trailColor: "#17366E",
					backgroundColor: "#17366E"
				})}
			/>
			<span className={"progress-bar-label"}>{statisticLabel}</span>
		</Col>
	);
	
};


export default ProgressBarStatisticComponent;
