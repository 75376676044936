import React, {useEffect, useState} from "react";

import REST_API_URLS from "@API/URLS";


import {Row, Col} from "react-bootstrap";

import {FaFacebookF, FaTwitter, FaYoutube, FaInstagram, IoLogoTiktok} from "react-icons/all";


import SocialsItemComponent from "@UIParts/Header/Socials/Element";
import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";





const {socials: {main_url: SOCIALS_URL}} = REST_API_URLS;


export const HeaderSocialsComponent = ({
	xs = 12, sm = null, lg = 3, xl = null,
	as = "aside"
}) => {
	
	
	const [SOCIALS, SET_SOCIALS] = useState(null);
	
	const GET_SOCIALS = async () => {
	    try {
	    	const RESPONSE = await fetch(SOCIALS_URL);
	    	const CONTENT = await RESPONSE.json();
		    SET_SOCIALS(CONTENT);
	    } catch (e) {
	    	console.log(e);
	    }
	};
	
	useEffect(() => GET_SOCIALS(), []);
	
	if (!SOCIALS) return <SingleContentLoaderComponent loaderColor={"#FFF"} xs={xs} sm={sm || xs} lg={lg} xl={xl || lg} />;
	
	
	const SocialsLinksList = SOCIALS.map((socialData, i) =>
		<SocialsItemComponent key={i} socialData={socialData} />
	);
	
	
	return (
		<Col as={as} xs={xs} sm={sm || xs} lg={lg} xl={xl || lg} className={"header-socials-component"}>
			<Row as={"ul"} className={"socials-links-list"}>
				{SocialsLinksList}
			</Row>
		</Col>
	);
	
};


export default HeaderSocialsComponent;
