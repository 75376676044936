import React from "react";


export const InterfaceContext = React.createContext(null);


export const INTERFACE_CONTEXT_DATA = {
	theme: {
		colors: {
			defaults: {
				primaryMainFontColor: "#17366E",
				secondaryMainFontColor: "#FFF",
				primarySpecialFontColor: "#1D4288",
				secondarySpecialFontColor: "#E2000F",
			}
		}
	}
};


export default InterfaceContext;
