import React from "react";


import ContainerSectionComponent from "@Elements/Containers/MainSection";


import PlayersListStatisticsTableComponent from "@GamesPages/Players/List/Statistics/Table";


const DATA = {
	TPTS: "Punkty",
	TREB: "Zbiórki",
	AST: "Asysty",
	BLK: "Bloki",
	STL: "Przechwyty",
	"3PT": "Rzuty 3 punktowe",
	FT: "Rzuty osobiste",
};


export const PlayersListStatisticsComponent = ({statisticType}) => {
	
	
	const StatisticsList = Object.entries(DATA).map(([key, name]) =>
		<PlayersListStatisticsTableComponent key={key} statisticID={key} statisticName={name} statisticType={statisticType} />
	);
	
	
	return (
		<ContainerSectionComponent id={"players-list-statistics-component"}>
			{StatisticsList}
		</ContainerSectionComponent>
	);
	
};


export default PlayersListStatisticsComponent;
