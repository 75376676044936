import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";


import {Container, Row} from "react-bootstrap";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import PlayerMainDataComponent from "@GamesPages/Players/Player/MainData";
import PlayerStatisticsComponent from "@GamesPages/Players/Player/Statistics";
import PlayerDescriptionComponent from "@GamesPages/Players/Player/Description";


import REST_API_URLS from "@API/URLS";

const {restApiRootURL} = REST_API_URLS;

export const PlayerDetailsComponent = ({match}) => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const playerSlug = match?.params?.playerSlug;
	
	
	const REST_API_URL = process.env.REACT_APP_REST_API_URL;
	const API_PLAYER_URL = `${restApiRootURL}/teams/players/player-by-slug/?player_slug=${playerSlug}`
	
	const [PLAYER_DATA, SET_PLAYER_DATA] = useState(null);
	
	
	const GET_PLAYER_DATA = async () => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const response = await fetch(API_PLAYER_URL);
			const responseContent = response.ok ? await response.json() : {error: true, code: response.status};
			SET_PLAYER_DATA(responseContent);
		} catch (e) {
			console.log(e);
			// alert("REQUEST ERROR");
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	
	useEffect(() => {
		GET_PLAYER_DATA();
		return () => SET_PLAYER_DATA(null);
	}, [playerSlug]);
	
	
	if (!PLAYER_DATA) return <MainContentLoaderComponent />;
	
	
	return (
		<Container fluid={true} id={"players-details-page"}>
			
			<PlayerMainDataComponent playerData={PLAYER_DATA} />
			
			<PlayerStatisticsComponent playerData={PLAYER_DATA} />
			
			<PlayerDescriptionComponent playerData={PLAYER_DATA} />

		</Container>
	);
	
};


export default PlayerDetailsComponent;
