import React, {useState} from "react";

import {Row, Col} from "react-bootstrap";


import LeagueTableComponent from "@GamesPages/Leagues/League/Information/Table";
import LeagueStatisticsComponent from "@GamesPages/Leagues/League/Information/Statistics";


export const LeagueInformationComponent = ({leagueData, selectedTeam = null, selectedTeamHandler = null}) => {
	
	
	if (!leagueData) return null;
	
	
	const {leagueID} = leagueData;
	
	
	return (
		<Col xs={12} lg={12} className={"league-information-component"}>
			<Row className={"league-information-content"}>
				
				<LeagueTableComponent
					leagueID={leagueID}
					selectedTeam={selectedTeam}
					selectedTeamHandler={selectedTeamHandler}
				/>
				
				<LeagueStatisticsComponent
					leagueID={leagueID}
					selectedTeam={selectedTeam}
				/>
				
			</Row>
		</Col>
	);
	
};


export default LeagueInformationComponent;
