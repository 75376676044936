import React from "react";

import parse from "html-react-parser";


import {Button, Col} from "react-bootstrap";


export const PlayerDescriptionTextComponent = ({playerDescription, descriptionContentVisibilityStatus, descriptionContentVisibilityStatusHandler}) => {
	
	
	return (
	<>
		
		<Col xs={12} className={"player-description-header"}>
			<Button
				variant={"primary"}
				className={"white-bordered-button"}
				onClick={() => descriptionContentVisibilityStatusHandler(!descriptionContentVisibilityStatus)}
			>
				<span>Statystyki Zawodnika</span>
			</Button>
		</Col>
		
		{descriptionContentVisibilityStatus && !!playerDescription &&
		<Col xs={12} className={"player-description-content"}>
			{parse(playerDescription)}
		</Col>
		}
		
	</>
	);
	
};


export default PlayerDescriptionTextComponent;
