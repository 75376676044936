import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import REST_API_URLS from "@API/URLS";


import {Container, Row} from "react-bootstrap";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import HomePageLeaguesTablesComponent from "@CommonPages/HomePage/Leagues/Tables";
import HomePageLeaguesStatisticsComponent from "@CommonPages/HomePage/Leagues/Statistics";
import HomePageLeaguesAwardsComponent from "@CommonPages/HomePage/Leagues/Awards";


export const HomePageLeaguesComponent = () => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const {leagues: {leagues: {list: {main_url: LEAGUES_LIST_API_URL}}}} = REST_API_URLS;
	
	
	const [LEAGUES_LIST, SET_LEAGUES_LIST] = useState(null);
	
	const GET_LEAGUES_LIST = async () => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const RESPONSE = await fetch(LEAGUES_LIST_API_URL);
			const CONTENT = await RESPONSE.json();
			SET_LEAGUES_LIST(CONTENT);
		} catch (e) {
			console.log(e);
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	useEffect(() => {
		if (!LEAGUES_LIST_API_URL) return null;
		GET_LEAGUES_LIST();
	}, []);
	
	
	if (!LEAGUES_LIST) return <MainContentLoaderComponent />;
	
	
	return (
		<Container fluid={true} className={`home-page-leagues-component`}>
			<Container className={"home-page-leagues-container"}>
				<Row className={"home-page-leagues-content"}>
					
					<HomePageLeaguesTablesComponent LEAGUES_LIST={LEAGUES_LIST} />
					
					<HomePageLeaguesStatisticsComponent LEAGUES_LIST={LEAGUES_LIST} />
					
					<HomePageLeaguesAwardsComponent LEAGUES_LIST={LEAGUES_LIST} />
				
				</Row>
			</Container>
		</Container>
	);
	
};


export default HomePageLeaguesComponent;
