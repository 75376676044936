import React from "react";


import {Col, Container, Row} from "react-bootstrap";


import ResultsPageLeaguesSwitcherComponent from "@GamesPages/Results/Switcher/Leagues";
import ResultsPageRoundsSwitcherComponent from "@GamesPages/Results/Switcher/Rounds";


export const ResultsPageDataSwitcherComponent = ({
	selectedLeague, selectedLeagueHandler,
	selectedLeagueRound, selectedLeagueRoundHandler,
	LEAGUES_LIST, LEAGUES_ROUNDS_LIST
}) => {
	
	
	if (LEAGUES_LIST?.error || LEAGUES_LIST?.length === 0) {
		return (
			<Container fluid={true} className={"page-component-no-content-block"}>
				<Container className={"page-component-switcher-container"}>
					<Row className={"page-component-switcher-content"}>
						<Col xs={12}>
							<span>{`Brak Lig`}</span>
						</Col>
					</Row>
				</Container>
			</Container>
		);
	}
	
	
	return (
		<Container fluid={true} className={"page-component-switcher-block results-page-switcher-component"}>
			<Container className={"page-component-switcher-container"}>
				<Row className={"page-component-switcher-content"}>
					
					<ResultsPageLeaguesSwitcherComponent
						selectedLeague={selectedLeague}
						selectedLeagueHandler={selectedLeagueHandler}
						leaguesList={LEAGUES_LIST}
					/>
					
					<ResultsPageRoundsSwitcherComponent
						selectedLeague={selectedLeague}
						selectedLeagueRound={selectedLeagueRound}
						selectedLeagueRoundHandler={selectedLeagueRoundHandler}
						leagueRounds={LEAGUES_ROUNDS_LIST}
					/>
				
				</Row>
			</Container>
		</Container>
	);
	
};


export default ResultsPageDataSwitcherComponent;
