import React from "react";


import {Col} from "react-bootstrap";


export const TeamDetailsNameComponent = ({teamData}) => {
	
	
	if (!teamData) return null;
	
	
	const {teamName, teamCity} = teamData;
	
	
	return (
		<Col xs={9} lg={7} className={"team-name"}>
			<span>{teamName}</span>
			<span>{teamCity}</span>
		</Col>
	);
	
};


export default TeamDetailsNameComponent;
