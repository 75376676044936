import React from "react";


import ContainerSectionComponent from "@Elements/Containers/MainSection";


export const TextPageComponent = ({pageSlug = null}) => {
	
	
	return (
		<ContainerSectionComponent>
			Text
		</ContainerSectionComponent>
	);
	
};


export default TextPageComponent;
