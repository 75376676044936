import React, {useEffect, useState} from "react";

import REST_API_URLS from "@API/URLS";


import {Col, Row} from "react-bootstrap";


import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";


import LeaguesStatisticsTableHeaderComponent from "@CommonPages/HomePage/Leagues/Statistics/Tables/Header";
import LeaguesStatisticsTablePlayerComponent from "@CommonPages/HomePage/Leagues/Statistics/Tables/Player";


const {leagues: {leagues: {league: {main_url: LEAGUE_API_URL}}}} = REST_API_URLS;


export const LeaguesStatisticsTableComponent = ({selectedLeagueID = 0}) => {
	
	
	const [selectedStatisticsType, setSelectedStatisticsType] = useState("TPTS");
	
	
	const [LEAGUE_STATISTICS_DATA, SET_LEAGUE_STATISTICS_DATA] = useState(null);
	
	const GET_LEAGUE_STATISTICS_DATA = async () => {
		const VARS = ["player_data=true", "results_number=10", `sort_type=${selectedStatisticsType}`].join("&");
		try {
			const RESPONSE = await fetch(`${LEAGUE_API_URL}/${selectedLeagueID}/league_statistics/?${VARS}`);
			const CONTENT = await RESPONSE.json();
			SET_LEAGUE_STATISTICS_DATA(CONTENT);
		} catch (e) {
			console.log(e);
		}
	};
	
	useEffect(() => {
		if (!selectedLeagueID || !LEAGUE_API_URL) return null;
		GET_LEAGUE_STATISTICS_DATA();
		return () => {
			SET_LEAGUE_STATISTICS_DATA(null);
		};
	}, [selectedLeagueID, selectedStatisticsType]);
	
	
	if (!selectedLeagueID || LEAGUE_STATISTICS_DATA?.error) return null;
	
	
	if (!LEAGUE_STATISTICS_DATA) return <SingleContentLoaderComponent lg={9} />;
	
	
	const StatisticsTable = LEAGUE_STATISTICS_DATA.map((player, i) =>
		<LeaguesStatisticsTablePlayerComponent
			key={i}
			playerData={player}
			index={i}
			selectedStatisticsType={selectedStatisticsType}
		/>
	);
	
	
	return (
		<Col xs={12} lg={9} className={"leagues-statistics-table"}>
			
			<LeaguesStatisticsTableHeaderComponent
				selectedStatisticsType={selectedStatisticsType}
				selectedStatisticsTypeHandler={setSelectedStatisticsType}
			/>
			
			<Row className={"statistics-table-data"}>
				{StatisticsTable}
			</Row>
			
		</Col>
	);
	
};


export default LeaguesStatisticsTableComponent;
