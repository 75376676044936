import React from "react";


import {Col} from "react-bootstrap";


import CREATE_LEAGUE_TABLE_FROM_ROUNDS_DATA from "@GamesPages/__FUNCTIONS/LeagueTable";


import TableListTeamComponent from "@GamesPages/Leagues/League/Information/Table/Team";


export const LeagueTableListComponent = ({selectedTeam = 0, selectedTeamHandler = null, LEAGUE_TABLE_DATA = null}) => {
	
	
	if (!LEAGUE_TABLE_DATA) return null;
	
	
	const LEAGUE_TABLE = CREATE_LEAGUE_TABLE_FROM_ROUNDS_DATA(LEAGUE_TABLE_DATA);
	
	
	const TableListTeams = LEAGUE_TABLE.map((team, i) =>
		<TableListTeamComponent
			key={i}
			teamData={{...team, teamTablePlace: i + 1}}
			selectedTeam={selectedTeam}
			selectedTeamHandler={selectedTeamHandler}
		/>
	);
	
	
	return (
		<Col xs={12} className={"league-table-team" + (selectedTeam === 1 ? "selected" : "")}>
			{TableListTeams}
		</Col>
	);
	
};


export default LeagueTableListComponent;
