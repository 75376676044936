import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";


import {Col, Row} from "react-bootstrap";


import ContainerSectionComponent from "@Elements/Containers/MainSection";
import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import TeamHeaderComponent from "@GamesPages/Teams/Team/Header";
import TeamPlayersComponent from "@GamesPages/Teams/Team/Players";


import noImage from "@Images/no-image.png";
import LeagueStatisticsTableComponent from "@GamesPages/Leagues/League/Information/Statistics/Table";


import REST_API_URLS from "@API/URLS";
import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";
const {restApiRootURL} = REST_API_URLS;


export const TeamDetailsComponent = ({match}) => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const teamSlug = match?.params?.teamSlug;
	
	const API_TEAM_URL = `${restApiRootURL}/teams/teams/team-by-slug/?team_slug=${teamSlug}`
	
	const [TEAM_DATA, SET_TEAM_DATA] = useState(null);
	
	
	const GET_TEAM_DATA = async () => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			console.log(API_TEAM_URL)
			const response = await fetch(API_TEAM_URL);
			const responseContent = response.ok ? await response.json() : {error: true, code: response.status};
			SET_TEAM_DATA(responseContent);
		} catch (e) {
			console.log(API_TEAM_URL)
			console.log(e);
			alert("REQUEST ERROR");
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	
	useEffect(() => {
		GET_TEAM_DATA();
		return () => SET_TEAM_DATA(null);
	}, [teamSlug]);
	
	
	const [LEAGUE_STATISTICS_DATA, SET_LEAGUE_STATISTICS_DATA] = useState(null);
	
	const GET_LEAGUE_STATISTICS_DATA = async () => {
		if (!TEAM_DATA) return null;
		const API_STATISTICS_DATA_URL = `${restApiRootURL}/leagues/leagues/${TEAM_DATA.team_league?.league_id}/league_statistics/?sort_type=${statisticsDataType}&results_number=5&team_id=${teamID}`;
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const response = await fetch(`${API_STATISTICS_DATA_URL}`);
			const responseContent = response.ok ? await response.json() : {error: true, code: response.status};
			SET_LEAGUE_STATISTICS_DATA(responseContent);
		} catch (e) {
			// alert("REQUEST ERROR");
			console.log(e);
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	
	
	const [statisticsPeriodType, setStatisticsPeriodType] = useState(1);
	const [statisticsDataType, setStatisticsDataType] = useState("TPTS");
	
	useEffect(() => GET_LEAGUE_STATISTICS_DATA(), [TEAM_DATA, statisticsPeriodType, statisticsDataType])
	
	if (!TEAM_DATA) return <MainContentLoaderComponent />;
	
	
	const {
		team_id: teamID,
		team_league: teamLeague,
		team_name: teamName, team_city: teamCity,
		team_sponsor: teamSponsor, team_facebook_url: teamFacebookURL,
		team_logo: teamLogo, team_image: teamImage,
		team_players: teamPlayers
	} = TEAM_DATA;
	
	console.log(LEAGUE_STATISTICS_DATA)
	return (
		<>
			
			<TeamHeaderComponent
				teamData={{
					teamID,
					teamLeague,
					teamName, teamCity,
					teamSponsor, teamLogo, teamFacebookURL
				}}
			/>
			
			<ContainerSectionComponent id={"team-details-image-component"}>
				
				<Col xs={12} lg={5} className={"team-image-component"}>
					<Row as={"picture"} className={"team-image-picture"}>
						<img src={teamImage || noImage}/>
					</Row>
				</Col>
				
				<Col xs={12} lg={{span: 6, offset: teamImage ? 1 : 1}} className={"team-players-statistics-component"}>
					<Row className={"team-players-statistics"}>
						
						{LEAGUE_STATISTICS_DATA?.length > 0 &&
						<Col xs={12} className={"players-statistics-header"}>
							<Row className={"players-statistics-header-content"}>
								<Col xs={6} className={"statistics-header-label"}>
									<span>{"Statystyki TOP 5"}</span>
								</Col>
								<Col xs={6} className={"statistics-header-switcher"}>
									<span className={statisticsPeriodType === 1 ? "selected" : ""} onClick={() => setStatisticsPeriodType(1)}>{`Średnio`}</span>
									<span className={"separator"}>{` / `}</span>
									<span className={statisticsPeriodType === 2 ? "selected" : ""} onClick={() => setStatisticsPeriodType(2)}>{`łącznie`}</span>
								</Col>
							</Row>
							
						</Col>
						}
						
						{!LEAGUE_STATISTICS_DATA && <SingleContentLoaderComponent />}
						
						{LEAGUE_STATISTICS_DATA && !LEAGUE_STATISTICS_DATA?.error &&
						<LeagueStatisticsTableComponent
							LEAGUE_STATISTICS_DATA={LEAGUE_STATISTICS_DATA.slice(0, 10)}
							statisticsDataType={statisticsDataType}
							statisticsDataTypeHandler={setStatisticsDataType}
							statisticsType={statisticsPeriodType}
						/>
						}
						
					</Row>
				</Col>
			
			</ContainerSectionComponent>
			
			
			
			<TeamPlayersComponent teamData={{teamPlayers}} />
			
		</>
	);
	
};


export default TeamDetailsComponent;
