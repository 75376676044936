import React from "react";


import {Col, Row} from "react-bootstrap";


export const StatisticsTableHeaderComponent = ({statisticName}) => {
	
	
	return (
		<Row className={"statistics-table-header"}>
			<Col xs={12} className={"statistics-table-header-content"}>
				<span>{statisticName}</span>
			</Col>
		</Row>
	);
	
};


export default StatisticsTableHeaderComponent;
