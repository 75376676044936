import React, {useState} from "react";


import {Col, Row} from "react-bootstrap";


import ContainerSectionComponent from "@Elements/Containers/MainSection";


import PlayersListStatisticsComponent from "@GamesPages/Players/List/Statistics";



export const GamesPlayersListComponent = () => {
	
	
	const [playersStatisticsDateRangeType, setPlayersStatisticsDateRangeType] = useState(1);
	
	
	return (
	<>
		<ContainerSectionComponent id={"players-list-header-component"}>
			
			<Col as={"h1"} xs={12} className={"main-section-header"}>
				<span>Zawodnicy</span>
			</Col>
			
		</ContainerSectionComponent>
	
		<ContainerSectionComponent id={"players-list-switcher-component"}>
			<Col xs={12} className={"statistics-type-switcher"}>
				<Row className={"statistics-type-switcher-content"}>
					<Col xs={12} lg={6} className={"statistics-type-switcher-option" + (playersStatisticsDateRangeType === 1 ? " active" : "")} onClick={() => setPlayersStatisticsDateRangeType(1)}>
						<span className={"option-name"}>Liderzy dnia</span>
						<span className={"bg-mask"}></span>
					</Col>
					<Col xs={12} lg={6} className={"statistics-type-switcher-option" + (playersStatisticsDateRangeType === 2 ? " active" : "")} onClick={() => setPlayersStatisticsDateRangeType(2)}>
						<span className={"option-name"}>Liderzy sezonu</span>
						<span className={"bg-mask"}></span>
					</Col>
				</Row>
			</Col>
		</ContainerSectionComponent>
		
		<PlayersListStatisticsComponent statisticType={playersStatisticsDateRangeType} />
		
	</>
	);
	
};


export default GamesPlayersListComponent;
