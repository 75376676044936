import React from "react";


import {Col} from "react-bootstrap";


import StatisticsTableHeaderComponent from "@GamesPages/Players/Player/Description/Table/Header";
import PlayerStatisticsTableRowComponent from "@GamesPages/Players/Player/Description/Table/Row";


export const PlayerStatisticsTableComponent = ({playerData = null}) => {
	
	
	if (!playerData) return null;
	
	
	const {
		fullStatistics, percentageStatistics
	} = playerData;
	
	
	return (
		<Col xs={12} className={"player-statistics-table"}>
		
			<StatisticsTableHeaderComponent />
			
			<PlayerStatisticsTableRowComponent
				rowLabelText={"Statystyki (łącznie)"}
				rowData={fullStatistics}
			/>
			
			<PlayerStatisticsTableRowComponent
				rowLabelText={"Statystyki (średnie)"}
				rowData={percentageStatistics}
			/>
			
		</Col>
	);
	
};


export default PlayerStatisticsTableComponent;
