import React, {useEffect, useState} from "react";

import REST_API_URLS from "@API/URLS";


import {Container, Row} from "react-bootstrap";


import NewsActiveArticleComponent from "@CommonPages/HomePage/News/Active";
import NewsArticlesListComponent from "@CommonPages/HomePage/News/List";


const {articles: {list: {main_url: ARTICLES_LIST_MAIN_URL}}} = REST_API_URLS;


export const HomePageNewsComponent = () => {
	
	
	const [HOME_PAGE_NEWS, SET_HOME_PAGE_NEWS] = useState(null);
	
	const GET_HOME_PAGE_NEWS = async () => {
		try {
			const RESPONSE = await fetch(`${ARTICLES_LIST_MAIN_URL}/?results_limit=4`);
			const CONTENT = await RESPONSE.json();
			SET_HOME_PAGE_NEWS(CONTENT);
			if (CONTENT?.length) {
				const {article_slug: articleSlug} = CONTENT[0]
				setHomePageActiveArticle(articleSlug);
			}
		} catch (e) {
			console.log(e);
		}
	};
	
	
	const homePageArticles = !HOME_PAGE_NEWS ? null : Object.fromEntries(HOME_PAGE_NEWS.map(article => {
		const {
			article_slug: articleSlug, article_title: articleTitle,
			article_short_content: articleShortContent, article_image: articleImage
		} = article;
		return [
			articleSlug, {articleSlug, articleTitle, articleShortContent, articleImage}
		];
	}));
	
	
	const [homePageActiveArticle, setHomePageActiveArticle] = useState( "");
	
	
	useEffect(() => {
		GET_HOME_PAGE_NEWS();
	}, []);
	
	
	if (homePageArticles && Object.entries(homePageArticles)?.length === 0) return null;
	
	
	return (
		<Container fluid={true} className={`home-page-news-component`}>
			<Container className={"home-page-news-container"}>
				<Row className={"home-page-news-content"}>
				
					<NewsActiveArticleComponent
						activeArticleData={homePageArticles ? homePageArticles[homePageActiveArticle] : null}
					/>
					
					<NewsArticlesListComponent
						articlesList={homePageArticles ? Object.values(homePageArticles) : null}
						activeArticle={homePageActiveArticle}
						activeArticleHandler={setHomePageActiveArticle}
					/>
				
				</Row>
			</Container>
		</Container>
	);
	
};


export default HomePageNewsComponent;
