import React, {useEffect, useRef, useState} from "react";


import {Col, Row} from "react-bootstrap";


export const HomePageLeaguesAwardsComponent = () => {
	
	
	return (
		<Col xs={12} lg={2} className={"home-page-leagues-awards"}>
			<Row className={"leagues-awards-content"}>
				
				<Col as={"h3"} xs={12} className={"home-page-leagues-header"}>
					<span>{`Zawodnik Kolejki`}</span>
				</Col>
				
				<Col xs={12} className={"no-data-content text-center"}>
					<span>{`Brak Informacji`}</span>
				</Col>
				
			</Row>
		</Col>
	);
	
};


export default HomePageLeaguesAwardsComponent;
