import React from "react";

import {GLOBALS} from "@Globals";


import {Col, Row} from "react-bootstrap";

import {NavLink} from "react-router-dom";


import noAvatarImage from "@Assets/Images/no-avatar.jpg";


export const PlayersListPlayerComponent = ({playerData}) => {

	
	if (!playerData) return null;
	
	
	const {
		player_profile_image: playerProfileImage,
		player_first_name: playerFirstName, player_last_name: playerLastName, player_slug: playerSlug,
		player_position: playerPosition,
		player_actual_team: playerTeam
	} = playerData;
	
	
	const POSITIONS = GLOBALS.GAMES.FIELD_POSITIONS;
	
	
	return (
		<Col className={"list-player-component"}>
			<Row className={"player-component-content"}>
				
				<Col xs={12} className={"player-avatar"}>
					<NavLink to={`/zawodnicy/${playerSlug}`} title={`${playerFirstName} ${playerLastName}`}>
						<img src={playerProfileImage || noAvatarImage} alt={``}/>
					</NavLink>
				</Col>
				
				<Col xs={12} className={"player-name"}>
					<NavLink to={`/zawodnicy/${playerSlug}`} title={`${playerFirstName} ${playerLastName}`}>
						<span>{`${playerFirstName} ${playerLastName}`}</span>
					</NavLink>
				</Col>
				
				<Col xs={12} className={"player-position"}>
					<span>{POSITIONS[playerPosition]}</span>
				</Col>
				
				{playerTeam && false &&
				<Col xs={12} className={"player-team"}>
				
				</Col>
				}
				
			</Row>
		</Col>
	)
	
};


export default PlayersListPlayerComponent;
