import React from "react";

import parse from "html-react-parser";


import {NavLink} from "react-router-dom";


import {Row, Col} from "react-bootstrap";


import noImage from "@Images/no-image.png";


export const ArticlesListItemComponent = ({articleData}) => {
	
	
	if (!articleData) return null;
	
	
	const {
		article_title: articleTitle,
		article_slug: articleSlug,
		article_short_content: articleShortContent,
		article_image: articleImage,
		article_author: articleAuthor,
		article_published_status: articlePublishedStatus,
		article_date: articleDate
	} = articleData;
	
	
	if (!articlePublishedStatus) return null;
	
	
	return (
		<Row as={"article"} className={"articles-list-item"}>
		
			<Col xs={12} lg={3} className={"article-image"}>
				<NavLink to={`/aktualnosci/${articleSlug}`} title={`Przejdź do artykułu`}>
					<img src={articleImage || noImage} alt={articleTitle} />
				</NavLink>
			</Col>
			
			<Col xs={12} lg={{span: 8, offset: 1}} className={"article-content"}>
				<h4 className={"article-title"}>
					<NavLink to={`/aktualnosci/${articleSlug}`} title={`Przejdź do artykułu`}>
						<span>{articleTitle}</span>
					</NavLink>
				</h4>
				<div className={"article-short-content"}>
					<NavLink to={`/aktualnosci/${articleSlug}`} title={`Przejdź do artykułu`}>
						{parse(articleShortContent || "")}
					</NavLink>
				</div>
				<h6 className={"article-information"}>
					<span className={"article-author"}>{articleAuthor}</span>
					<span className={"article-date"}>{(new Date(articleDate)).toLocaleDateString()}</span>
				</h6>
			</Col>
			
		</Row>
	);
	
};


export default ArticlesListItemComponent;
