import React from "react";

import {Redirect, Route, Switch} from "react-router-dom";


// import TeamsListComponent from "@GamesPages/Teams/List";
import TeamDetailsComponent from "@GamesPages/Teams/Team";
import TeamsListPageComponent from "@GamesPages/Teams/List";


export const GamesTeamsComponent = () => {
	
	
	return (
		<Switch>
			
			<Route path={"/druzyny"} exact render={props =>
				<TeamsListPageComponent {...props} />
			}
			/>
			
			<Route path={"/druzyny/:teamSlug"} render={props =>
				<TeamDetailsComponent {...props} />
			}
			/>
			
			<Redirect to={`/404`} render={(props) => null} />
		
		</Switch>
	);
	
};


export default GamesTeamsComponent;
