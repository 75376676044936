import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import REST_API_URLS from "@API/URLS";


import {Col} from "react-bootstrap";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


import ArticlesListItemComponent from "@CommonPages/NewsPage/List/Item";


const {articles: {list: {main_url: ARTICLES_LIST_MAIN_URL}}} = REST_API_URLS;


export const ArticlesListComponent = () => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const [ARTICLES_LIST, SET_ARTICLES_LIST] = useState(null);
	
	const GET_ARTICLES_LIST = async () => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const RESPONSE = await fetch(ARTICLES_LIST_MAIN_URL);
			const CONTENT = await RESPONSE.json();
			SET_ARTICLES_LIST(CONTENT);
		} catch (e) {
			console.log(e);
			SET_ARTICLES_LIST({error: true});
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	useEffect(() => {
		if (!ARTICLES_LIST_MAIN_URL) return null;
		GET_ARTICLES_LIST();
	}, [ARTICLES_LIST_MAIN_URL]);
	console.log(ARTICLES_LIST)
	
	if (!ARTICLES_LIST) return <MainContentLoaderComponent loaderColor={"#17366E"} />;
	
	
	if (ARTICLES_LIST.error) return null;
	
	
	const ArticlesList = ARTICLES_LIST.map((article, i) =>
		<ArticlesListItemComponent key={i} articleData={article} />
	);

	
	return (
		<Col xs={12} className={"articles-list-component"}>
			{ArticlesList}
		</Col>
	);
	
	
};


export default ArticlesListComponent;
