import {GLOBAL_APPLICATION_DATA} from "@Actions";


const initialState = {data: null};


export const ApplicationDataReducer = (state = initialState, action) => {
	
	switch (action.type) {
		
		case GLOBAL_APPLICATION_DATA:
			return {...state, data: action.data};
			
		default:
			return state;
		
	}
	
};


export default ApplicationDataReducer;
