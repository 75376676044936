import React from 'react';
import ReactDOM from 'react-dom';


import reportWebVitals from './reportWebVitals';


import {Provider as StoreProvider} from "react-redux";
import APPLICATION_STORE from "@Store";


import GlobalDataContext, {GLOBAL_DATA} from "@Globals";
import InterfaceContext , {INTERFACE_CONTEXT_DATA} from "@Contexts/Interface";


import Application from "./Application";


ReactDOM.render(
    <React.StrictMode>
        <StoreProvider store={APPLICATION_STORE}>
            <GlobalDataContext.Provider value={GLOBAL_DATA}>
                <InterfaceContext.Provider value={INTERFACE_CONTEXT_DATA}>
                    <Application />
                </InterfaceContext.Provider>
            </GlobalDataContext.Provider>
        </StoreProvider>
    </React.StrictMode>,
    document.getElementById('root')
);


reportWebVitals();
