import React from "react";

import {useSelector} from "react-redux";

import REST_API_URLS from "@API/URLS";
import INFORMATION from "@DATA/GUI/Information";


import {NavLink} from "react-router-dom";

import {Col} from "react-bootstrap";


import ContainerSectionComponent from "@Elements/Containers/MainSection";


import HeaderNavigationComponent from "@UIParts/Header/Navigation";
import HeaderSocialsComponent from "@UIParts/Header/Socials";


import PLKA_LOGO from "@Images/plka_logo_color.png";


const {apiRootURL} = REST_API_URLS;
const {PLKA_URL} = INFORMATION;


export const ApplicationHeaderComponent = () => {
	
	
	const APPLICATION_DATA = useSelector(state => state?.data)?.data?.settings;
	
	const {league_name: leagueName, league_logo: leagueLogo} = APPLICATION_DATA || {};
	
	
	return (
		<ContainerSectionComponent as={"header"} id={"application-header-component"}>
		
			<Col xs={6} lg={1} className={"header-logo-component"}>
				{!!leagueLogo &&
				<NavLink to={"/"} title={leagueName?.value || ""} className={"league-logo"}>
					<img src={`${apiRootURL}${leagueLogo.image}`} alt={leagueName?.value || ""}/>
				</NavLink>
				}
			</Col>
			
			<Col xs={6} lg={1} className={"header-plka-logo-component"}>
				<a href={PLKA_URL} title={"Strona PLKA"} target={"_blank"} rel={"noopener norefferer"}>
					<img src={PLKA_LOGO} alt={""} />
				</a>
			</Col>
			
			<HeaderNavigationComponent xs={3} lg={8} />
			
			<HeaderSocialsComponent xs={9} lg={2} />
		
		</ContainerSectionComponent>
	);
	
};


export default ApplicationHeaderComponent;
