import {combineReducers} from "redux";

import LocalizationReducer from "@Reducers/Localization";
import InterfaceReducer from "@Reducers/Interface";
import ApplicationDataReducer from "@Reducers/Data";


const ApplicationReducer = combineReducers({
	localization: LocalizationReducer,
	interface: InterfaceReducer,
	data: ApplicationDataReducer
});


export default ApplicationReducer;
