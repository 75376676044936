import React from "react";


import {Col, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";


export const ResultsListComponent = ({leagueRoundNumber = null, leagueRoundGames = null}) => {
	
	
	if (!leagueRoundGames) return null;
	
	
	const FORMAT_GAME_DATE = (gameDate) => {
		
		const gameDateObject = new Date(gameDate);
		
		const gameDay = gameDateObject.getDate();
		const gameMonth = gameDateObject.getMonth() + 1;
		const gameYear = gameDateObject.getFullYear();
		
		const gameHour = gameDateObject.getHours();
		const gameMinutes = gameDateObject.getMinutes();
		
		const date = [
			`${gameDay < 10 ? "0": ""}${gameDay}`,
			`${gameMonth < 10 ? "0": ""}${gameMonth}`,
			gameYear
		].join(".")
		
		return `${date} ${(gameHour < 10 ? "0" : "") + gameHour}:${(gameMinutes < 10 ? "0" : "") + gameMinutes}`;
		
	};
	
	
	const LeagueRoundGames = leagueRoundGames.map(roundGame => {
		
		const {
			league_game_home_team_data: {team_name: homeTeamName, team_slug: homeTeamSlug}, league_game_home_team_points: homeTeamPoints,
			league_game_away_team_data: {team_name: awayTeamName, team_slug: awayTeamSlug}, league_game_away_team_points: awayTeamPoints,
			league_game_date: leagueGameDate
		} = roundGame;
		
		return (
			<Row className={"results-list-game"}>
				<Col xs={5} lg={4} className={"game-home-team-name"}>
					<NavLink to={`/druzyny/${homeTeamSlug}`}>
						<span>{homeTeamName}</span>
					</NavLink>
				</Col>
				<Col xs={"auto"} lg={"auto"} className={"game-home-team-points"}>
					<span>{homeTeamPoints}</span>
				</Col>
				<Col xs={"auto"} className={"game-teams-separator"}>
					<span>{":"}</span>
				</Col>
				<Col xs={"auto"} lg={"auto"} className={"game-away-team-points"}>
					<span>{awayTeamPoints}</span>
				</Col>
				<Col xs={5} lg={4} className={"game-away-team-name"}>
					<NavLink to={`/druzyny/${awayTeamSlug}`}>
						<span>{awayTeamName}</span>
					</NavLink>
				</Col>
				<Col xs={12} lg={"auto"} className={"game-date-time"}>
					<span>{FORMAT_GAME_DATE(leagueGameDate)}</span>
				</Col>
			</Row>
		);
		
	});
	
	
	return (
		<Col xs={12} className={"results-list-component"}>
			<Row className={"results-list-component-content"}>
				
				<Col xs={12} lg={2} className={"results-list-component-header"}>
					<h4 className={"results-list-header"}>
						<span>{leagueRoundNumber} {`Kolejka`}</span>
					</h4>
				</Col>
				
				<Col xs={12} lg={{span: 10}} className={"results-list-games-list"}>
					{LeagueRoundGames}
				</Col>
				
			</Row>
		</Col>
	);
	
};


export default ResultsListComponent;
