export const REST_API_REQUEST_STATUS = "REST_API_REQUEST_STATUS";

export const CHANGE_REST_API_REQUEST_STATUS_ACTION = (status = false) => {
	return {
		type: REST_API_REQUEST_STATUS,
		status: status
	};
};


export const GLOBAL_BLACKOUT_STATUS = "GLOBAL_BLACKOUT_STATUS";

export const CHANGE_GLOBAL_BLACKOUT_STATUS_ACTION = (status = false) => {
	return {
		type: GLOBAL_BLACKOUT_STATUS,
		status: status
	};
};
