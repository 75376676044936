import React from "react";


import {Col, Row} from "react-bootstrap";


import {ProgressBarStatisticComponent} from "@GamesPages/Players/Player/Description/ProgressBars/SingleStatistic";


export const PlayerProgressBarsStatisticsComponent = ({playerData}) => {
	
	
	if (!playerData) return null;
	
	const {EFF_FG, EFF_3PT, EFF_FT} = playerData;

	
	return (
		<Col xs={12} lg={{span: 10, offset: 1}} className={"player-progressbar-statistics"}>
			<Row className={"progressbar-statistics-content"}>
				<ProgressBarStatisticComponent
					statisticLabel={"Rzuty z pola"}
					statisticValue={EFF_FG.toFixed(2)}
				/>
				<ProgressBarStatisticComponent
					statisticLabel={"Rzuty za 3"}
					statisticValue={EFF_3PT.toFixed(2)}
				/>
				<ProgressBarStatisticComponent
					statisticLabel={"Rzuty Osobiste"}
					statisticValue={EFF_FT.toFixed(2)}
				/>
			</Row>
		</Col>
	);
	
};


export default PlayerProgressBarsStatisticsComponent;
