import {REST_API_REQUEST_STATUS, GLOBAL_BLACKOUT_STATUS} from "@Actions";


const initialState = {
	restApiRequestStatus: false,
	globalBlackoutPopup: false
};


export const InterfaceReducer = (state = initialState, action) => {
	
	switch (action.type) {
		
		case REST_API_REQUEST_STATUS:
			return {...initialState, restApiRequestStatus: action.status};
		
		case GLOBAL_BLACKOUT_STATUS:
			return {...initialState, globalBlackoutPopup: action.status};
			
	}
	
	return state;
	
};


export default InterfaceReducer;
