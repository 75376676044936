import React from "react";

import ROUTER from "@ROUTER";


import {NavLink} from "react-router-dom";


import {Col, Row} from "react-bootstrap";


import noAvatarImage from "@Images/no-avatar.jpg";


export const LeaguesTableTeamComponent = ({teamData = null, teamPosition = 1}) => {
	
	
	const {teamsPage: {singleTeam: {main_url: TEAM_PAGE_URL}}} = ROUTER["PL"];
	
	
	if (!teamData) return null;
	
	
	const {teamSlug, teamLogo, teamName, teamResults} = teamData;
	
	const teamTablePoints = teamResults.W * 2 + teamResults.L;
	
	
	return (
		<Row className={"tables-table-team"}>
			
			<Col xs={1} className={"table-team-position"}>
				<span>{teamPosition}</span>
			</Col>
			
			<Col xs={2} className={"table-team-logo"}>
				<NavLink to={`${TEAM_PAGE_URL}/${teamSlug}`}>
					<img src={teamLogo || noAvatarImage} alt={teamName} />
				</NavLink>
			</Col>
			
			<Col xs={5} className={"table-team-name"}>
				<NavLink to={`${TEAM_PAGE_URL}/${teamSlug}`}>
					<span>{teamName}</span>
				</NavLink>
			</Col>
			
			<Col xs={2} className={"table-team-results"}>
				<span>{teamResults.W} - {teamResults.L}</span>
			</Col>
			
			<Col xs={2} className={"table-team-points"}>
				<span>{teamTablePoints} PKT</span>
			</Col>
			
		</Row>
	);
	
};


export default LeaguesTableTeamComponent;
