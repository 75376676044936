import React from "react";


import {Col, Row} from "react-bootstrap";


export const LeaguesStatisticsLeaguesListComponent = ({LEAGUES_LIST, selectedLeagueID = 0, selectedLeagueHandler = null}) => {
	
	
	if (!LEAGUES_LIST) return null;
	
	
	const LeaguesList = LEAGUES_LIST.map(league => {
		const {league_id: leagueID, league_name: leagueName} = league;
		return (
			<Row key={leagueID} className={"leagues-list-league"}>
				<Col xs={12} className={"leagues-list-name" + (leagueID === selectedLeagueID ? " selected" : "")}>
					<span onClick={() => selectedLeagueHandler(leagueID)}>{leagueName}</span>
				</Col>
			</Row>
		);
	});
	
	
	return (
		<Col xs={12} lg={3} className={"leagues-statistics-leagues-list-component"}>
			{LeaguesList}
		</Col>
	);
	
};


export default LeaguesStatisticsLeaguesListComponent;
