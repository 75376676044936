import React, {useState, useEffect} from "react";

import REST_API_URLS from "@API/URLS";


import {NavLink, Redirect} from "react-router-dom";

import {Col, Form, Row} from "react-bootstrap";


import noAvatarImage from "@Images/no-avatar.jpg";
import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";


const {
	teams: {list: {main_url: TEAMS_LIST_URL}}
} = REST_API_URLS;


export const TeamsListComponent = () => {
	
	
	const [TEAMS_LIST, SET_TEAMS_LIST] = useState(null);
	
	const GET_TEAMS_LIST = async () => {
		try {
			const RESPONSE = await fetch(`${TEAMS_LIST_URL}/?all_results=true`);
			const CONTENT = await RESPONSE.json();
			SET_TEAMS_LIST(CONTENT);
		} catch (e) {
			console.log(e);
		}
	};
	
	useEffect(() => {
		GET_TEAMS_LIST();
		return () => {};
	}, []);
	
	
	const [teamsSearchValue, setTeamsSearchValue] = useState("");
	
	if (!TEAMS_LIST) return <SingleContentLoaderComponent xs={12} lg={6} />
	
	
	if (TEAMS_LIST?.error) return <Redirect to={"/404"} />;
	
	
	const FilteredTeamsList = TEAMS_LIST.filter(team => {
		const {team_name: teamName, team_city: teamCity} = team;
		return (
			teamName?.toLowerCase().includes(teamsSearchValue.toLowerCase())
			||
			teamCity?.toLowerCase().includes(teamsSearchValue.toLowerCase())
		)
	}).map(team => {
		const {
			team_name: teamName, team_city: teamCity, team_slug: teamSlug, team_logo: teamLogo, team_league: teamLeague
		} = team;
		const {league_short_name: leagueShortName, league_slug: leagueSlug} = teamLeague || {};
		return (
			<Col key={teamSlug} xs={6} className={"games-elements-list-element teams-list-team"}>
				<Row className={"list-element-content"}>
					<Col xs={12} lg={3} className={"team-logo"}>
						<NavLink to={`/druzyny/${teamSlug}`} title={`Przejdź do Profilu Drużyny`} className={"team-logo-link"}>
							<img src={teamLogo || noAvatarImage} alt={teamName} className={"team-logo-image"}/>
						</NavLink>
					</Col>
					<Col xs={12} lg={9} className={"team-name"}>
						<NavLink to={`/druzyny/${teamSlug}`} title={`Przejdź do Profilu Drużyny`} className={"team-name-link"}>
							<span>{teamName} {teamCity}</span>
						</NavLink>
					</Col>
					<Col xs={12} className={"team-league"}>
						{!!teamLeague &&
						<NavLink to={`/tabele/${leagueSlug}`} title={`Przejdź do Strony Ligi`}>
							<span>Liga: <strong>{leagueShortName}</strong></span>
						</NavLink>
						}
						{!teamLeague &&
						<span>Liga: -</span>
						}
					</Col>
				</Row>
			</Col>
		);
	});
	
	
	return (
		<Col xs={12} lg={6} className={"teams-list-component"}>
			
			<Row className={"games-elements-list-header-container"}>
				<Col xs={12} as={"h2"} className={"games-elements-list-header"}>
					<span>{`Drużyny`}</span>
				</Col>
			</Row>
			
			<Row className={"games-elements-list-search-container"}>
				<Col xs={12} className={"games-elements-list-search"}>
					<Form.Control
						placeholder={`Wyszukaj Drużynę`}
						value={teamsSearchValue}
						onChange={e => setTeamsSearchValue(e.target.value)}
					/>
				</Col>
			</Row>
			
			<Row className={"games-elements-list teams-list"}>
				{FilteredTeamsList}
			</Row>
		
		</Col>
	);
	
};


export default TeamsListComponent;
