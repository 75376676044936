import React from "react";


import {Col} from "react-bootstrap";


import StatisticsTableHeaderComponent from "@GamesPages/Leagues/League/Information/Statistics/Table/Header";
import StatisticsTablePlayerComponent from "@GamesPages/Leagues/League/Information/Statistics/Table/Player";


export const LeagueStatisticsTableComponent = ({LEAGUE_STATISTICS_DATA, statisticsType, statisticsDataType, statisticsDataTypeHandler}) => {
	
	
	if (!LEAGUE_STATISTICS_DATA || !LEAGUE_STATISTICS_DATA?.length) return null;
	
	
	const StatisticsTable = LEAGUE_STATISTICS_DATA.map((player, i) =>
		<StatisticsTablePlayerComponent
			key={i}
			statisticsType={statisticsType}
			playerData={{...player, tablePosition: i + 1}}
			selectedStatisticsType={statisticsDataType}
		/>
	);
	
	
	return (
		<Col xs={12} className={"league-statistics-table"}>
		
			<StatisticsTableHeaderComponent
				selectedStatisticsType={statisticsDataType}
				selectedStatisticsTypeHandler={statisticsDataTypeHandler}
			/>
			
			{StatisticsTable}
		
		</Col>
	);
	
};


export default LeagueStatisticsTableComponent;
