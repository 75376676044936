import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import REST_API_URLS from "@API/URLS";


import scrollToDomElement from "@Utilities/Scrolling";


import DefaultWarningMessageComponent from "@UI/Messages/Default/Warning";

import MainContentLoaderComponent from "@Elements/Loaders/MainContent";

import PageContainerComponent from "@UI/Containers/PageContainer";
import PageHeaderContainerComponent from "@UI/Containers/HeaderContainer";
import PageContentContainerComponent from "@UI/Containers/ContentContainer";


import FilesGroupComponent from "@CommonPages/DownloadPage/FIlesGroup";


const {files: {groups: {main_url: FILES_GROUPS_API_URL}}} = REST_API_URLS;


const TEXTS = {
	PL: {
		header_text: "Do Pobrania",
		no_files_message_text: "Brak Plików do Pobrania"
	}
};


export const DownloadPageComponent = () => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const PAGE_CONTAINER_REF = useRef(null);
	
	useEffect(() => {
		if (!PAGE_CONTAINER_REF.current) return null;
		scrollToDomElement(PAGE_CONTAINER_REF.current, 250);
	}, []);
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage || "PL";
	
	const {header_text: headerText, no_files_message_text: noFilesMessageText} = TEXTS[ACTIVE_LANGUAGE];
	
	
	const [FILES_LIST, SET_FILES_LIST] = useState(null);
	
	const GET_FILES_LIST = async() => {
	    DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
	    try {
	        const RESPONSE = await fetch(FILES_GROUPS_API_URL);
	        const CONTENT = await RESPONSE.json();
		    SET_FILES_LIST(CONTENT);
	    } catch (e) {
	    	console.log(e);
	    }
	    DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	useEffect(() => GET_FILES_LIST(), []);
	
	
	if (!FILES_LIST) return <MainContentLoaderComponent />;
	
	
	if (FILES_LIST?.error) return null;
	
	
	const FilesGroupsList = FILES_LIST.map((filesGroup, i) =>
		<FilesGroupComponent filesGroupData={filesGroup} />
	);
	
	
	return (
		<PageContainerComponent forwardRef={PAGE_CONTAINER_REF} id={"download-page-component"}>
		
			<PageHeaderContainerComponent headerText={headerText} />
			
			<PageContentContainerComponent cssClasses={"download-page-content"}>
			{FilesGroupsList.length > 0 ?
				FilesGroupsList
				:
				<DefaultWarningMessageComponent messageText={noFilesMessageText} />
			}
			</PageContentContainerComponent>
			
		</PageContainerComponent>
	);
	
}


export default DownloadPageComponent;
