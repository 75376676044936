import React from "react";

import {useSelector} from "react-redux";


import {Col, Row} from "react-bootstrap";


import SmallDataLoaderComponent from "@UI/Loaders/SmallDataLoader";


import {BallImageIcon, PlayerImageIcon, PlayersImageIcon} from "@Icons";


const TEXTS = {
	PL: {
		teams_word_text: "Drużyny",
		players_word_text: "Zawodnicy",
		games_number_text: "Ilość meczów"
	}
};


export const FooterInformationComponent = () => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage || "PL";
	
	const {
		teams_word_text: teamsWordText,
		players_word_text: playersWordText,
		games_number_text: gamesNumberText
	} = TEXTS[ACTIVE_LANGUAGE];
	
	
	const APPLICATION_DATA = useSelector(state => state?.data)?.data?.information;
	
	
	const {
		teams_number: leagueTeamsNumber,
		players_number: leaguePlayersNumber,
		games_number: leagueGamesNumber
	} = APPLICATION_DATA || {};

	
	return (
		<Col as={`aside`} xs={12} lg={{span: 5}} className={`footer-league-info`}>
			<Row as={`ul`} className={`league-info-list`}>
				
				<Col className={`league-info-element`}>
					<h5 className={`league-info-element-name`}>{teamsWordText}</h5>
					<img src={PlayersImageIcon} alt={teamsWordText} className={`league-info-element-icon`} />
					<h6 className={`league-info-element-value`}>
						{!!leagueTeamsNumber ? <span>{leagueTeamsNumber}</span>: <SmallDataLoaderComponent color={"#FFF"} />}
					</h6>
				</Col>
				
				<Col className={`league-info-element`}>
					<h5 className={`league-info-element-name`}>{playersWordText}</h5>
					<img src={PlayerImageIcon} alt={playersWordText} className={`league-info-element-icon`} />
					<h6 className={`league-info-element-value`}>
						{!!leaguePlayersNumber ? <span>{leaguePlayersNumber}</span> : <SmallDataLoaderComponent color={"#FFF"} />}
					</h6>
				</Col>
				
				<Col className={`league-info-element`}>
					<h5 className={`league-info-element-name`}>{gamesNumberText}</h5>
					<img src={BallImageIcon} alt={gamesNumberText} className={`league-info-element-icon`} />
					<h6 className={`league-info-element-value`}>
						{!!leagueGamesNumber ? <span>{leagueGamesNumber}</span> : <SmallDataLoaderComponent color={"#FFF"} />}
					</h6>
				</Col>
			
			</Row>
		</Col>
	);
	
};


export default FooterInformationComponent;
