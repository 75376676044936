import React, {useEffect, useState} from "react";


import {Col, Row} from "react-bootstrap";

import {AiFillCaretDown} from "react-icons/all";


import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";


export const ResultsPageLeaguesSwitcherComponent = ({
	selectedLeague, selectedLeagueHandler, leaguesList = null
}) => {
	
	
	const [leaguesListOpenStatus, setLeaguesListOpenStatus] = useState(false);
	
	const changeSelectedLeagueHandler = (leagueID) => {
		setLeaguesListOpenStatus(false);
		selectedLeagueHandler(leagueID);
	};
	
	
	useEffect(() => {
		if (selectedLeague || !leaguesList || leaguesList?.error) return null;
		const {league_id: selectedLeagueID} = leaguesList[0];
		selectedLeagueHandler(selectedLeagueID);
		return () => {};
	}, [leaguesList]);
	
	
	if (!leaguesList || !selectedLeague) {
		return <SingleContentLoaderComponent xs={12} />
	}
	
	
	const SELECTED_LEAGUE = leaguesList.filter(league => {
		const {league_id: leagueID} = league;
		return selectedLeague === leagueID;
	})[0];
	
	
	const LeaguesList = leaguesList.filter(league => league !== SELECTED_LEAGUE).map(league => {
		const {league_id: leagueID, league_name: leagueName} = league;
		return (
			<Row key={leagueID} className={"options-list-option"} onClick={() => changeSelectedLeagueHandler(leagueID)}>
				<Col xs={12} className={"options-list-option-name"}>
					<span>{leagueName}</span>
				</Col>
			</Row>
		);
	});
	
	
	const {
		league_id: selectedLeagueID, league_name: selectedLeagueName
	} = SELECTED_LEAGUE;
	
	
	return (
		<Col xs={6} lg={4} className={"leagues-switcher-component switcher-option-component"}>
			<Row className={"leagues-switcher-component-content"}>
				
				{selectedLeagueID &&
				<Col xs={12} className={"leagues-switcher-active-option switcher-active-option"} onClick={() => setLeaguesListOpenStatus(!leaguesListOpenStatus)}>
					<span className={"switcher-active-option-label"}>{selectedLeagueName}</span>
					<span className={"switcher-active-option-icon"}>
						<AiFillCaretDown />
					</span>
				</Col>
				}
				
				{leaguesListOpenStatus &&
				<Col xs={12} className={"switcher-options-list"}>
					{LeaguesList}
				</Col>
				}
				
			</Row>
		</Col>
	);
	
};


export default ResultsPageLeaguesSwitcherComponent;
