import React, {useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION, GLOBAL_APPLICATION_DATA_ACTION} from "@Actions";


import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";

import {Container} from "react-bootstrap";


import GlobalApiRequestLoaderComponent from "@/UI/Loaders/GlobalApiRequest";


import ApplicationHeaderComponent from "@UIParts/Header";
import ApplicationFooterComponent from "@UIParts/Footer";
import ApplicationSponsorsComponent from "@UIParts/Sponsors";
import ApplicationCopyrightsComponent from "@UIParts/Copyrights";


import Error404PageComponent from "@ErrorPages/404";

import TextPageComponent from "@Views/ELements/TextPage";
import NewsPageComponent from "@CommonPages/NewsPage";
import ArticlePageComponent from "@CommonPages/NewsPage/Article";

import HomePageComponent from "@CommonPages/HomePage";
import ContactPageComponent from "@CommonPages/ContactPage";

import PartnersPageComponent from "@CommonPages/PartnersPage";

import ApplicationGamesComponent from "@GamesPages";
import ApplicationResultsBarComponent from "@UIParts/Results";
import LeagueResultsPageComponent from "@GamesPages/Results";
import GamesLeaguesComponent from "@GamesPages/Leagues";
import REST_API_URLS from "@API/URLS";
import DownloadPageComponent from "@CommonPages/DownloadPage";



const {restApiRootURL} = REST_API_URLS;


export const Application = () => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const REST_API_REQUEST_STATUS = useSelector(state => state?.interface)?.restApiRequestStatus;
	
	const GLOBAL_BLACKOUT_POPUP_STATUS = useSelector(state => state?.interface)?.globalBlackoutPopup;
	
	
	useEffect(() => {
		GET_APPLICATION_DATA();
		return () => {
			DISPATCHER(GLOBAL_APPLICATION_DATA_ACTION(null));
		};
	}, []);
	
	const GET_APPLICATION_DATA = async () => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const response = await fetch(`${restApiRootURL}/application/data/`);
			const content = await response.json();
			DISPATCHER(GLOBAL_APPLICATION_DATA_ACTION(content));
		} catch (e) {
			console.log(e);
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	
	return (
		<Router>
			
			<Route path={`/`} render={(props) => <ApplicationHeaderComponent {...props} />} />
			<Route path={`/`} exact render={(props) => <ApplicationResultsBarComponent {...props} />} />
			
			<Switch>
				
				<Route path={`/`} exact render={(props) => <HomePageComponent {...props} />} />
				
				<Route path={`/kontakt`} exact render={(props) => <ContactPageComponent {...props} />} />
				<Route path={`/aktualnosci`} exact render={(props) => <NewsPageComponent {...props} />} />
				<Route path={`/aktualnosci/:articleSlug`} exact render={(props) => <ArticlePageComponent {...props} />} />
				<Route path={`/partnerzy`} exact render={(props) => <PartnersPageComponent {...props} />} />
				<Route path={`/do-pobrania`} exact render={(props) => <DownloadPageComponent {...props} />} />
				
				<Route path={`/polityka-prywatnosci`} exact render={(props) => <TextPageComponent {...props} pageSlug={"polityka-prywatnosci"} />} />
				<Route path={`/regulamin`} exact render={(props) => <TextPageComponent {...props} />} pageSlug={"regulamin"} />
				
				<Route path={`/terminarz`} exact render={(props) => <LeagueResultsPageComponent {...props} />} />
				<Route path={`/tabele`} render={(props) => <GamesLeaguesComponent {...props} />} />
				
				<Route path={`/`} render={(props) => <ApplicationGamesComponent {...props} />} />
				
				<Redirect to={`/404`} render={(props) => <Error404PageComponent {...props} />} />
				
			</Switch>
			
			<Route path={`/`} render={(props) => <ApplicationSponsorsComponent {...props} />} />
			<Route path={`/`} render={(props) => <ApplicationFooterComponent {...props} />} />
			<Route path={`/`} render={(props) => <ApplicationCopyrightsComponent {...props} />} />
			
			{GLOBAL_BLACKOUT_POPUP_STATUS &&
			<Container
				fluid={true}
				className={"global-blackout-component"}
				style={{
					position: "fixed", top: 0, left: 0,
					width: "100%", height: "100%",
					background: "rgba(0, 0, 0, 0.4)",
					zIndex: 1000
				}}
			/>
			}
			
			{!!REST_API_REQUEST_STATUS &&
			<GlobalApiRequestLoaderComponent />
			}
			
		</Router>
	);
	
};


export default Application;
