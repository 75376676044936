import React from "react";


import {Col, Row} from "react-bootstrap";


import ContainerSectionComponent from "@Elements/Containers/MainSection";


import PlayersListPlayerComponent from "@GamesPages/Players/List/Player";
import PageHeaderContainerComponent from "@UI/Containers/HeaderContainer";


export const TeamPlayersComponent = ({teamData}) => {
	
	
	if (!teamData) return null;
	
	
	const {teamPlayers} = teamData;
	
	
	const TeamPlayers = teamPlayers.map((player, i) =>
		<PlayersListPlayerComponent key={i} playerData={player} />
	);
	
	
	return (
		<>
		<PageHeaderContainerComponent headerText={"Zawodnicy"} />
		<ContainerSectionComponent id={"players-list-component"}>
			<Col xs={12} className={"players-list-component"}>
				<Row className={"players-list-content"}>
					{TeamPlayers}
				</Row>
			</Col>
		</ContainerSectionComponent>
		</>
	);
	
};


export default TeamPlayersComponent;
