import React, {useEffect, useRef} from "react";


import {Container} from "react-bootstrap";


import scrollToDomElement from "@Utilities/Scrolling";


import HomePageNewsComponent from "@CommonPages/HomePage/News";
import HomePageLeaguesComponent from "@CommonPages/HomePage/Leagues";


export const HomePageComponent = () => {
	
	
	const HOME_PAGE_REF = useRef(null);
	
	useEffect(() => {
		scrollToDomElement(HOME_PAGE_REF.current, 250);
	}, []);
	
	
	return (
		<Container fluid={true} className={"home-page-component"} ref={HOME_PAGE_REF}>
			
			<HomePageNewsComponent />
			
			<HomePageLeaguesComponent />
		
		</Container>
	);
	
};


export default HomePageComponent;
