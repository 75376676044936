import React, {useState, useEffect} from "react";

import REST_API_URLS from "@API/URLS";


import {Col} from "react-bootstrap";


import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";


import LeagueTableGenerator from "@CommonPages/HomePage/Leagues/Tables/Table/tableGenerator";
import LeaguesTableTeamComponent from "@CommonPages/HomePage/Leagues/Tables/Table/Team";


const {leagues: {leagues: {league: {main_url: LEAGUE_API_URL}}}} = REST_API_URLS;


export const LeaguesTablesTableComponent = ({selectedLeagueID = 0}) => {
	
	
	const [LEAGUE_TABLE_DATA, SET_LEAGUE_TABLE_DATA] = useState(null);
	
	const GET_LEAGUE_TABLE_DATA = async () => {
		try {
			const RESPONSE = await fetch(`${LEAGUE_API_URL}/${selectedLeagueID}/league_rounds/`);
			const CONTENT = await RESPONSE.json();
			SET_LEAGUE_TABLE_DATA(CONTENT);
		} catch (e) {
			console.log(e);
		}
	};
	
	useEffect(() => {
		if (!selectedLeagueID || !LEAGUE_API_URL) return null;
		GET_LEAGUE_TABLE_DATA();
		return () => {
			SET_LEAGUE_TABLE_DATA(null);
		};
	}, [selectedLeagueID]);
	
	
	const GET_LEAGUE_TEAMS = async () => {
		try {
			const RESPONSE = await fetch(`${LEAGUE_API_URL}/${selectedLeagueID}/league-teams/`);
			const CONTENT = await RESPONSE.json();
			SET_LEAGUE_TABLE_DATA(CONTENT);
		} catch (e) {
			console.log(e);
		}
		
	};
	
	useEffect(() => {
		if (LEAGUE_TABLE_DATA?.length !== 0) return null;
		SET_LEAGUE_TABLE_DATA(null);
		GET_LEAGUE_TEAMS();
	}, [LEAGUE_TABLE_DATA])
	
	
	if (!selectedLeagueID) return null;
	
	
	if (!LEAGUE_TABLE_DATA) return <SingleContentLoaderComponent lg={9} />;
	
	
	let TABLE_DATA;
	if (LEAGUE_TABLE_DATA && LEAGUE_TABLE_DATA["0"]?.league_round_games) {
		TABLE_DATA = LeagueTableGenerator(LEAGUE_TABLE_DATA);
	} else if (LEAGUE_TABLE_DATA?.length) {
		TABLE_DATA = [];
		for (let team of LEAGUE_TABLE_DATA) {
			TABLE_DATA.push({
				teamLogo: team.team_logo,
				teamName: team.team_name,
				teamSlug: team.team_slug,
				teamPoints: {I: 0, O: 0},
				teamResults: {W: 0, L: 0, D: 0}
			})
		}
	}
	
	
	if (!TABLE_DATA) return null;
	
	
	const LeagueTable = TABLE_DATA.map((team, i) =>
		<LeaguesTableTeamComponent key={i} teamData={team} teamPosition={i + 1} />
	);
	
	
	return (
		<Col xs={12} lg={9} className={"leagues-tables-table"}>
			{LeagueTable}
		</Col>
	);
	
};


export default LeaguesTablesTableComponent;
