import React from "react";


import {Alert, Col} from "react-bootstrap";


export const DefaultWarningMessageComponent = ({xs = 12, lg = 12, messageText = ""}) => {
	
	
	if (!messageText) return null;
	
	
	return (
		<Col xs={xs} lg={lg} as={Alert} variant={"warning"}>
			<span>{messageText}</span>
		</Col>
	);
	
};


export default DefaultWarningMessageComponent;
