import React, {useEffect, useState} from "react";


import {Col, Row} from "react-bootstrap";


import LeaguesStatisticsLeaguesListComponent from "@CommonPages/HomePage/Leagues/Statistics/Leagues";
import LeaguesStatisticsTableComponent from "@CommonPages/HomePage/Leagues/Statistics/Tables";


export const HomePageLeaguesStatisticsComponent = ({LEAGUES_LIST}) => {
	
	
	const [selectedLeague, setSelectedLeague] = useState(0);
	
	
	useEffect(() => {
		if (!LEAGUES_LIST || !LEAGUES_LIST?.length) return null;
		const {league_id: leagueID} = LEAGUES_LIST[0];
		setSelectedLeague(leagueID);
	}, [LEAGUES_LIST]);
	
	
	if (!LEAGUES_LIST) return null;
	
	
	return (
		<Col xs={12} lg={5} className={"home-page-leagues-statistics"}>
			<Row className={"leagues-statistics-content"}>
				
				<Col as={"h3"} xs={12} className={"home-page-leagues-header"}>
					<span>{`Statystyki Indywidualne / Drużynowe`}</span>
				</Col>
				
				<LeaguesStatisticsLeaguesListComponent
					LEAGUES_LIST={LEAGUES_LIST}
					selectedLeagueID={selectedLeague}
					selectedLeagueHandler={setSelectedLeague}
				/>
				
				<LeaguesStatisticsTableComponent
					selectedLeagueID={selectedLeague}
				/>
			
			</Row>
		</Col>
	);
	
};


export default HomePageLeaguesStatisticsComponent;
