import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";


import {NavLink} from "react-router-dom";

import {Row, Col} from "react-bootstrap";


import SmallDataLoaderComponent from "@UI/Loaders/SmallDataLoader";

import REST_API_URLS from "@API/URLS";

const {restApiRootURL} = REST_API_URLS;

export const TeamStatisticsComponent = ({teamData}) => {
	
	
	const {teamID} = teamData;
	
	
	const API_TEAM_URL = `${restApiRootURL}/teams/teams/${teamID}/team-information`;
	
	
	const [TEAM_DATA, SET_TEAM_DATA] = useState(null);
	
	const GET_TEAM_DATA = async () => {
		try {
			const response = await fetch(`${API_TEAM_URL}`);
			const responseContent = response.ok ? await response.json() : {error: true, code: response.status};
			SET_TEAM_DATA(responseContent);
		} catch (e) {
			console.log(e);
			// alert("REQUEST ERROR");
		}
	};
	
	useEffect(() => {
		GET_TEAM_DATA();
		return () => SET_TEAM_DATA(null);
	}, []);
	
	
	const {
		results: teamResults, points: teamPoints,
		events: teamStats, last_game: lastGame, next_game: nextGame
	} = TEAM_DATA || {};
	
	
	return (
		<Col xs={12} className={"team-statistics-table"}>
			<Row className={"team-statistics"}>
				<Col className={"statistics-table-cell"}>
					<span className={"cell-label"}>Punkty Zespołu</span>
					{TEAM_DATA ? <span className={"cell-value"}>{teamPoints?.IN || "-"}</span> : <SmallDataLoaderComponent color={"#FFF"} />}
				</Col>
				<Col className={"statistics-table-cell"}>
					<span className={"cell-label"}>Punkty Rywali</span>
					{TEAM_DATA ? <span className={"cell-value"}>{teamPoints?.OUT || "-"}</span> : <SmallDataLoaderComponent color={"#FFF"} />}
				</Col>
				<Col className={"statistics-table-cell"}>
					<span className={"cell-label"}>Zbiórki Zespołu</span>
					{TEAM_DATA ? <span className={"cell-value"}>{(teamStats?.DREB || 0) + (teamStats?.TREB || 0) + (teamStats?.OREB || 0) || "-"}</span> : <SmallDataLoaderComponent color={"#FFF"} />}
				</Col>
				<Col className={"statistics-table-cell"}>
					<span className={"cell-label"}>Asysty Zespołu</span>
					{TEAM_DATA ? <span className={"cell-value"}>{teamStats?.AST || "-"}</span> : <SmallDataLoaderComponent color={"#FFF"} />}
				</Col>
				<Col className={"statistics-table-cell"}>
					<span className={"cell-label"}>Bilans</span>
					{TEAM_DATA ? <span className={"cell-value"}>{`${teamResults.W} - ${teamResults.L + teamResults.D}`}</span> : <SmallDataLoaderComponent color={"#FFF"} />}
				</Col>
				<Col xs={6} lg={2}  className={"statistics-table-cell game-table-cell previous-game"}>
					<span className={"cell-label"}>Ostatni mecz</span>
					{TEAM_DATA ?
						lastGame ?
						<NavLink to={`/druzyny/${lastGame?.opponent["team_slug"]}`} title={lastGame?.opponent["team_name"]} className={"cell-value"}>
							<span>{lastGame?.opponent["team_name"]}</span>
						</NavLink>
							:
						<span className={"cell-value"}>{"-"}</span>
						:
						<SmallDataLoaderComponent color={"#FFF"}/>
					}
				</Col>
				<Col xs={6} lg={2}  className={"statistics-table-cell game-table-cell next-game"}>
					<span className={"cell-label"}>Następny mecz</span>
					{TEAM_DATA ?
						nextGame ?
							<NavLink to={`/druzyny/${nextGame?.opponent["team_slug"]}`} title={nextGame?.opponent["team_name"]} className={"cell-value"}>
								<span>{nextGame?.opponent["team_name"]}</span>
							</NavLink>
							:
							<span className={"cell-value"}>{"-"}</span>
						:
						<SmallDataLoaderComponent color={"#FFF"}/>
					}
				</Col>
			</Row>
		</Col>
	);
	
};


export default TeamStatisticsComponent;
