import React, {useContext, useState, useEffect} from "react";

import {useDispatch} from "react-redux";
import {CHANGE_GLOBAL_BLACKOUT_STATUS_ACTION} from "@Actions";

import GlobalDataContext from "@Globals";


import {Col, Row} from "react-bootstrap";

import {FaRegWindowClose, GiHamburgerMenu} from "react-icons/all";


import HeaderNavigationLinkComponent from "@UIParts/Header/Navigation/Element";


export const HeaderNavigationComponent = ({
	xs = 2, sm = null, lg = 6, xl = null,
	as = "nav"
}) => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const [navigationDisplayStatus, setNavigationDisplayStatus] = useState(false);
	
	const changeNavigationDisplayStatus = (e) => {
		e.preventDefault();
		setNavigationDisplayStatus(!navigationDisplayStatus);
		DISPATCHER(CHANGE_GLOBAL_BLACKOUT_STATUS_ACTION(!navigationDisplayStatus));
	};
	
	
	useEffect(() => {
		window.addEventListener("resize", WINDOW_RESIZE_LISTENER);
		return () => {
			window.removeEventListener("resize", WINDOW_RESIZE_LISTENER);
			setNavigationDisplayStatus(false);
		}
	}, []);
	
	
	const WINDOW_RESIZE_LISTENER = () => {
		if (window.innerWidth > 991) {
			setNavigationDisplayStatus(false);
			DISPATCHER(CHANGE_GLOBAL_BLACKOUT_STATUS_ACTION(false));
		}
	};
	
	
	const NAVIGATION = useContext(GlobalDataContext)?.routerData;
	
	const NavigationLinksList = Object.entries(NAVIGATION).map(([linkName, linkData]) =>
		<HeaderNavigationLinkComponent key={linkName} linkData={linkData} listDisplayHandler={setNavigationDisplayStatus} />
	);
	
	
	return (
		<Col as={as} xs={xs} sm={sm || xs} lg={lg} xl={xl || lg} className={"header-navigation-component"}>
		
			<a
				href={"#main-menu"}
				className={"main-navigation-display-switcher" + (navigationDisplayStatus ? " opened" : "")}
				onClick={e => changeNavigationDisplayStatus(e)}
			>
				<GiHamburgerMenu />
			</a>
			
			<Row as={"ul"} className={"navigation-links-list" + (navigationDisplayStatus ? " opened" : "")}>
				
				<a
					href={"#close-main-menu"}
					className={"main-navigation-close-handler"}
					onClick={e => changeNavigationDisplayStatus(e)}
				>
					<FaRegWindowClose />
				</a>
				
				{NavigationLinksList}
			</Row>
			
		</Col>
	);
	
};


export default HeaderNavigationComponent;
