import React from "react";


import {Col} from "react-bootstrap";


import {PulseLoader} from "react-spinners";


export const SingleContentLoaderComponent = ({
	xs = 12, sm = null, lg = null, xl = null, double = true,
	loaderSize = 15, loaderColor = "#1D4288", loaderElementsMargin = 3,
	forwardRef = null,
	cssClasses = ""
}) => {

	
	cssClasses = "single-content-loader text-center" + (cssClasses ? ` ${cssClasses}` : "");
	
	
	return (
		<Col xs={xs} sm={sm || xs} lg={lg || sm || xs} xl={xl || lg || sm || xs} className={cssClasses} ref={forwardRef}>
			<PulseLoader size={loaderSize} color={loaderColor} margin={loaderElementsMargin} />
			{double && <PulseLoader size={loaderSize} color={loaderColor} margin={loaderElementsMargin} />}
		</Col>
	);
	
};


export default SingleContentLoaderComponent;
