import React from "react";


import {Col} from "react-bootstrap";


export const ContactPageInformationComponent = ({dataLabel = "", dataValue = "", dataURL = ""}) => {
	
	
	return (
		<Col xs={12} lg={3} className={"contact-page-information"}>
			{dataLabel && <span className={"data-label"}>{dataLabel}:</span>}
			<a href={dataURL} className={"data-value"} title={dataLabel}>{dataValue}</a>
		</Col>
	);
	
};


export default ContactPageInformationComponent;
