import React from "react";


import {Col} from "react-bootstrap";


import ContainerSectionComponent from "@Elements/Containers/MainSection";


import ArticlesListComponent from "@CommonPages/NewsPage/List";


const TEXTS = {
	PL: {
		header_text: "Aktualności"
	}
};


export const NewsPageComponent = () => {

	
	return (
		<ContainerSectionComponent id={"news-page-component"}>
			
			<Col as={"h1"} xs={12} className={"main-section-header"}>
				<span>Aktualności</span>
			</Col>
			
			<ArticlesListComponent />
			
		</ContainerSectionComponent>
	);
	
};


export default NewsPageComponent;
