export const ROUTER = {
	PL: {
		homePage: {main_url: "/"},
		newsPage: {
			articlesList: {main_url: "/aktualnosci"},
			singleArticle: {main_url: "/aktualnosci"},
		},
		leaguesPage: {
			leaguesList: {main_url: "/ligi"},
			singleLeague: {main_url: "/ligi"},
		},
		resultsURL: {},
		teamsPage: {
			main_url: "/druzyny",
			teamsList: {main_url: "/druzyny"},
			singleTeam: {main_url: "/druzyny"}
		},
		playersPage: {
			main_url: "/zawodnicy",
			playersList: {main_url: "/zawodnicy"},
			singlePlayer: {main_url: "/zawodnicy"},
		}
	},
};


export default ROUTER;
