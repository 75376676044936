import React, {useEffect, useState} from "react";


import {Col, Row} from "react-bootstrap";


import LeaguesTablesLeaguesListComponent from "@CommonPages/HomePage/Leagues/Tables/Leagues";
import LeaguesTablesTableComponent from "@CommonPages/HomePage/Leagues/Tables/Table";


export const HomePageLeaguesTablesComponent = ({LEAGUES_LIST}) => {
	
	
	const [selectedLeague, setSelectedLeague] = useState(0);
	
	
	useEffect(() => {
		if (!LEAGUES_LIST || !LEAGUES_LIST?.length) return null;
		const {league_id: leagueID} = LEAGUES_LIST[0];
		setSelectedLeague(leagueID);
	}, [LEAGUES_LIST]);
	
	
	if (!LEAGUES_LIST) return null;
	
	
	return (
		<Col xs={12} lg={5} className={"home-page-leagues-tables"}>
			<Row className={"leagues-tables-content"}>
			
				<Col as={"h3"} xs={12} className={"home-page-leagues-header"}>
					<span>{`Tabele Ligowe`}</span>
				</Col>
				
				<LeaguesTablesLeaguesListComponent
					LEAGUES_LIST={LEAGUES_LIST}
					selectedLeagueID={selectedLeague}
					selectedLeagueHandler={setSelectedLeague}
				/>
				
				<LeaguesTablesTableComponent
					selectedLeagueID={selectedLeague}
				/>
				
			</Row>
		</Col>
	);
	
};


export default HomePageLeaguesTablesComponent;
