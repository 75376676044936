import React from "react";


import {Container, Row} from "react-bootstrap";


export const ContainerSectionComponent = ({
	as = "div", id = null, classes = "",
	children = null
}) => {
	
	
	if (!children) return null;
	
	
	return (
		<Container as={as} fluid={true} id={id} className={"main-section-component" + (classes ? ` ${classes}` : "")}>
			<Container className={"main-section-container"}>
				<Row className={"main-section-content"}>
					{children}
				</Row>
			</Container>
		</Container>
	);
	
};


export default ContainerSectionComponent;
