import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import {Row, Col, Alert} from "react-bootstrap";


import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";


import LeagueStatisticsHeaderComponent from "@GamesPages/Leagues/League/Information/Statistics/Header";
import LeagueStatisticsTableComponent from "@GamesPages/Leagues/League/Information/Statistics/Table";
import REST_API_URLS from "@API/URLS";
const {restApiRootURL} = REST_API_URLS;

export const LeagueStatisticsComponent = ({leagueID, selectedTeam = null}) => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const REST_API_URL = process.env.REACT_APP_REST_API_URL;
	const REQUESTED_TEAM = selectedTeam ? `&team_id=${selectedTeam}` : "";
	const API_STATISTICS_DATA_URL = `${restApiRootURL}/leagues/leagues/${leagueID}/league_statistics`;
	
	
	const [LEAGUE_STATISTICS_DATA, SET_LEAGUE_STATISTICS_DATA] = useState(null);
	
	const GET_LEAGUE_STATISTICS_DATA = async () => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const response = await fetch(`${API_STATISTICS_DATA_URL}/?sort_type=${statisticsDataType}${REQUESTED_TEAM}`);
			const responseContent = response.ok ? await response.json() : {error: true, code: response.status};
			SET_LEAGUE_STATISTICS_DATA(responseContent);
		} catch (e) {
			// alert("REQUEST ERROR");
			console.log(e);
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	const [statisticsDataType, setStatisticsDataType] = useState("TPTS");
	
	useEffect(() => {
		if (!REST_API_URL || !leagueID || !statisticsDataType) return null;
		GET_LEAGUE_STATISTICS_DATA();
		return () => SET_LEAGUE_STATISTICS_DATA(null);
	}, [leagueID, selectedTeam, statisticsDataType]);
	
	
	const [statisticsType, setStatisticsType] = useState(1);
	
	console.log(LEAGUE_STATISTICS_DATA);
	return (
		<Col xs={12} lg={5} className={"league-statistics-component"}>
			<Row className={"league-statistics-list"}>
				
				{LEAGUE_STATISTICS_DATA?.error && LEAGUE_STATISTICS_DATA?.code === 404 &&
				<Col xs={12} as={Alert} variant={"warning"}>
					<span>{`Brak danych statystycznych`}</span>
				</Col>
				}
				
				{LEAGUE_STATISTICS_DATA?.length > 0 &&
				<LeagueStatisticsHeaderComponent
					statisticsType={statisticsType}
					statisticsTypeHandler={setStatisticsType}
				/>
				}
				
				{!LEAGUE_STATISTICS_DATA && <SingleContentLoaderComponent />}
				
				{LEAGUE_STATISTICS_DATA && !LEAGUE_STATISTICS_DATA?.error &&
				<LeagueStatisticsTableComponent
					LEAGUE_STATISTICS_DATA={LEAGUE_STATISTICS_DATA.slice(0, 10)}
					statisticsDataType={statisticsDataType}
					statisticsDataTypeHandler={setStatisticsDataType}
					statisticsType={statisticsType}
				/>
				}
				
			</Row>
		</Col>
	);
	
};


export default LeagueStatisticsComponent;
