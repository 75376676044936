import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import {Row, Col} from "react-bootstrap";


import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";


import ContainerSectionComponent from "@Elements/Containers/MainSection";
import REST_API_URLS from "@API/URLS";


const {restApiRootURL} = REST_API_URLS;


export const PlayerStatisticsComponent = ({playerData}) => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const {
		player_id: playerID,
		player_height: playerHeight, player_weight: playerWeight,
		player_birthdate: playerBirthdate, player_nationality: playerNationality,
		player_team: {team_league: {league_id: playerLeagueID}}
	} = (playerData || {});
	
	
	const API_PLAYER_STATISTICS_URL = `${restApiRootURL}/leagues/leagues/${playerLeagueID}/league_statistics`
	
	const [PLAYER_STATISTICS_DATA, SET_PLAYER_STATISTICS_DATA] = useState(null);
	
	
	const GET_PLAYER_STATISTICS_DATA = async () => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const response = await fetch(`${API_PLAYER_STATISTICS_URL}/?player_id=${playerID}`);
			const responseContent = response.ok ? await response.json() : {error: true, code: response.status};
			SET_PLAYER_STATISTICS_DATA(responseContent);
		} catch (e) {
			// alert("REQUEST ERROR");
			console.log(e);
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	useEffect(() => {
		if (!playerID) return null;
		GET_PLAYER_STATISTICS_DATA();
		return () => SET_PLAYER_STATISTICS_DATA(null);
	}, [playerID]);
	
	
	const playerAge = playerBirthdate ?
		Math.floor(((new Date()).getTime() - (new Date(playerBirthdate)).getTime()) / 1000 / 3600 / 24 / 365)
		:
		null
	;
	
	
	if (!playerData) return null;
	
	
	const {
		player_games: playerGames
	} = PLAYER_STATISTICS_DATA || {};
	
	
	return (
		<ContainerSectionComponent id={"player-details-statistics-component"}>
			<Col xs={12} lg={{span: 9, offset: 2}} className={"player-statistics-component"}>
				<Row className={"statistics-component-content"}>
				
					<Col xs={3} lg={"auto"} className={"player-statistics-statistic ppg-statistic"}>
						<span className={"data-label"}>PPG</span>
						{PLAYER_STATISTICS_DATA ?
						<span className={"data-value"}>{+(PLAYER_STATISTICS_DATA?.TPTS / playerGames).toFixed(1) || "-"}</span>
							:
						<SingleContentLoaderComponent />}
					</Col>
					
					<Col xs={3} lg={"auto"} className={"player-statistics-statistic rpg-statistic"}>
						<span className={"data-label"}>RPG</span>
						{PLAYER_STATISTICS_DATA ?
						<span className={"data-value"}>{+(PLAYER_STATISTICS_DATA?.TREB / playerGames).toFixed(1) || "-"}</span>
							:
						<SingleContentLoaderComponent />}
					</Col>
					
					<Col xs={3} lg={"auto"} className={"player-statistics-statistic apg-statistic"}>
						<span className={"data-label"}>APG</span>
						{PLAYER_STATISTICS_DATA ?
						<span className={"data-value"}>{+(PLAYER_STATISTICS_DATA?.AST / playerGames).toFixed(1) || "-"}</span>
							:
						<SingleContentLoaderComponent />}
					</Col>
					
					<Col xs={3} lg={"auto"} className={"player-statistics-statistic eval-statistic"}>
						<span className={"data-label"}>EVAL</span>
						{PLAYER_STATISTICS_DATA ?
						<span className={"data-value"}>{+(PLAYER_STATISTICS_DATA?.EVAL / playerGames).toFixed(1) || "-"}</span>
							:
						<SingleContentLoaderComponent />}
					</Col>
					
					<Col xs={6} lg={3} className={"player-statistics-statistic data-statistic"}>
						<Row className={"single-data-statistic"}>
							<span className={"data-label"}>Wzrost</span>
							<span className={"data-value"}>{playerHeight} cm</span>
						</Row>
						<Row className={"single-data-statistic"}>
							<span className={"data-label"}>Waga</span>
							<span className={"data-value"}>{playerWeight} kg</span>
						</Row>
					</Col>
					
					<Col xs={6} lg={3} className={"player-statistics-statistic data-statistic"}>
						<Row className={"single-data-statistic"}>
							<span className={"data-label"}>Wiek</span>
							<span className={"data-value"}>{playerAge + " lat" || "b.d."}</span>
						</Row>
						<Row className={"single-data-statistic"}>
							<span className={"data-label"}>Obywatelstwo</span>
							<span className={"data-value"}>{playerNationality || "b.d."}</span>
						</Row>
					</Col>
					
				</Row>
			</Col>
		</ContainerSectionComponent>
	);
	
};


export default PlayerStatisticsComponent;
