import React from "react";

import INFORMATION from "@DATA/GUI/Information";
import ROUTER from "@ROUTER";


import {NavLink} from "react-router-dom";


import {Col, Row} from "react-bootstrap";


import noAvatarImage from "@Images/no-avatar.jpg";


export const LeaguesStatisticsTablePlayerComponent = ({playerData = null, index = 0, selectedStatisticsType = "TPTS"}) => {
	
	
	if (!playerData) return null;
	
	const {
		player_games: playerGames,
		player_data: {player_first_name: playerFirstName, player_last_name: playerLastName, player_profile_image: playerProfileImage, player_slug: playerSlug},
		team_data: {team_logo: teamLogo, team_name: teamName, team_slug: teamSlug}
	} = playerData;
	
	const playerStatisticsValue = playerData[selectedStatisticsType];
	
	
	const {
		teamsPage: {singleTeam: {main_url: TEAM_PAGE_URL}},
		playersPage: {singlePlayer: {main_url: PLAYER_PAGE_URL}}
	} = ROUTER["PL"];
	
	const {statistics: {types: statisticsTypes}} = INFORMATION;
	
	
	return (
		<Col xs={12} className={"statistics-table-player"}>
			<Row className={"statistics-table-player-content"}>
				
				<Col xs={1} className={"statistics-table-position"}>
					<span>{index + 1}</span>
				</Col>
				
				<Col xs={7} className={"statistics-table-player-data"}>
					<Row className={"player-data-content"}>
						<Col xs={4} className={"player-avatar"}>
							<NavLink to={`${PLAYER_PAGE_URL}/${playerSlug}`} title={`Przejdź do profilu`}>
								<img src={playerProfileImage || noAvatarImage} alt={""} />
							</NavLink>
						</Col>
						<Col xs={8} className={"player-information"}>
							<Row className={"player-name-data"}>
								<Col xs={12} className={"player-name"}>
									<NavLink to={`${PLAYER_PAGE_URL}/${playerSlug}`} title={`Przejdź do profilu`}>
										<span>{playerFirstName} {playerLastName}</span>
									</NavLink>
								</Col>
							</Row>
							<Row className={"player-team-data"}>
								<Col xs={3} className={"player-team-image"}>
									<NavLink to={`${TEAM_PAGE_URL}/${teamSlug}`} title={`Przejdź do profilu drużyny`}>
										<img src={teamLogo || noAvatarImage} alt={""}/>
									</NavLink>
								</Col>
								<Col xs={9} className={"player-team-name"}>
									<NavLink to={`${TEAM_PAGE_URL}/${teamSlug}`} title={`Przejdź do profilu drużyny`}>
										<span>{teamName}</span>
									</NavLink>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
				
				<Col xs={4} className={"statistics-table-data"}>
					<Row className={"statistics-table-data-content"}>
						<Col xs={6} className={"statistics-data-value"}>
							<span>{(playerStatisticsValue / playerGames).toFixed(1)}</span>
						</Col>
						<Col xs={6} className={"statistics-data-label"}>
							<span>{statisticsTypes[selectedStatisticsType].label} / mecz</span>
						</Col>
					</Row>
				</Col>
				
			</Row>
		</Col>
	);
	
};


export default LeaguesStatisticsTablePlayerComponent;
