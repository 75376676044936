import React from "react";

import {useSelector} from "react-redux";


import {Col, Row} from "react-bootstrap";


import DefaultSuccessMessageComponent from "@UI/Messages/Default/Success";


import ContactPageInformationComponent from "@CommonPages/ContactPage/Content/Information";
import ContactPageFormComponent from "@CommonPages/ContactPage/Form";


const TEXTS = {
	PL: {
		message_sent_text: "Wiadomość została wysłana"
	}
};


export const ContactPageContentComponent = ({formSentStatus = false, sendMessageHandler = null}) => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage || "PL";
	const GLOBAL_INFORMATION = useSelector(state => state?.data)?.data?.settings;
	
	const {message_sent_text: messageSentText} = TEXTS[ACTIVE_LANGUAGE];
	const {
		main_email_address: mainEmailAddress, main_phone_number: mainPhoneNumber,
		contact_email_address: contactEmailAddress, contact_phone_number: contactPhoneNumber
	} = GLOBAL_INFORMATION || {};
	
	
	const phoneNumber = contactPhoneNumber?.value || mainPhoneNumber?.value || "";
	const phoneNumberURL = "+48" + phoneNumber.replaceAll(" ", "");
	
	const emailAddress = contactEmailAddress?.value || mainEmailAddress?.value || "";
	
	return (
		<Col xs={12}>
			
			<Row className={"contact-page-content-sections"}>
				
				{!!phoneNumber &&
				<ContactPageInformationComponent
					dataLabel={"TEL"}
					dataValue={phoneNumber}
					dataURL={`tel:${phoneNumberURL}`}
				/>
				}
				
				{!!emailAddress &&
				<ContactPageInformationComponent
					dataValue={emailAddress}
					dataURL={`mailto:${emailAddress}`}
				/>
				}
				
			{!formSentStatus ?
				<ContactPageFormComponent
					sendMessageHandler={sendMessageHandler}
				/>
				:
				<DefaultSuccessMessageComponent
					messageText={messageSentText} lg={6}
				/>
			}
			
			</Row>
		
		</Col>
	);
	
};


export default ContactPageContentComponent;
