import React from "react";


import ContainerSectionComponent from "@Elements/Containers/MainSection";

import TeamStatisticsComponent from "@GamesPages/Teams/Team/Header/Stats";
import TeamDetailsLogoComponent from "@GamesPages/Teams/Team/Header/Logo";
import TeamDetailsNameComponent from "@GamesPages/Teams/Team/Header/Name";
import TeamDetailsInfoComponent from "@GamesPages/Teams/Team/Header/Info";


export const TeamHeaderComponent = ({teamData}) => {
	
	
	if (!teamData) return null;
	
	
	const {
		teamLeague: {league_name: teamLeagueName, league_slug: teamLeagueSlug},
		teamName, teamCity, teamLogo, teamFacebookURL = "https://facebook.com"
	} = teamData;
	
	
	return (
		
		<ContainerSectionComponent id={"team-details-header-component"}>
			
			<TeamDetailsLogoComponent teamData={{teamName, teamLogo}} />
			
			<TeamDetailsNameComponent teamData={{teamName, teamCity}} />
			
			<TeamDetailsInfoComponent teamData={{teamLeagueName, teamLeagueSlug, teamFacebookURL}} />
			
			<TeamStatisticsComponent teamData={teamData} />
			
		</ContainerSectionComponent>
	);
	
	
};


export default TeamHeaderComponent;
