import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";
import {Alert, Col, Container, Form, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";


import noAvatarImage from "@Images/no-avatar.jpg";
import REST_API_URLS from "@API/URLS";


const {restApiRootURL} = REST_API_URLS;


export const LeaguesListComponent = () => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const API_LEAGUES_URL = `${restApiRootURL}/leagues/leagues/`;
	
	
	const [LEAGUES_LIST, SET_LEAGUES_LIST] = useState(null);
	const [TEAMS_LIST, SET_TEAMS_LIST] = useState(null);
	
	const [searchForTeamValue, setSearchForTeamValue] = useState("");
	
	const GET_LEAGUES_LIST = async () => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const response = await fetch(API_LEAGUES_URL);
			const responseContent = response.ok ? await response.json() : {error: true, code: response.status};
			SET_LEAGUES_LIST(responseContent);
		} catch (e) {
			alert("REQUEST ERROR");
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	useEffect(() => {
		if (!restApiRootURL) return null;
		GET_LEAGUES_LIST();
		GET_TEAMS_LIST();
		return () => SET_LEAGUES_LIST(null);
	}, []);
	
	
	const GET_TEAMS_LIST = async () => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const response = await fetch(`${restApiRootURL}/teams/teams/?all_results=true&requested_fields=team_name,team_short_name,team_logo,team_leagues_list,team_city,team_slug`);
			const responseContent = response.ok ? await response.json() : {error: true, code: response.status};
			SET_TEAMS_LIST(responseContent);
		} catch (e) {
			alert("REQUEST ERROR");
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	
	if (!LEAGUES_LIST || LEAGUES_LIST?.error) return null;
	
	
	const LeaguesList = LEAGUES_LIST.map(league => {
		
		const {
			league_name: leagueName, league_slug: leagueSlug,
			league_image: leagueImage, league_short_name: leagueShortName,
		} = league;
		
		return (
			<Col key={leagueSlug} xs={6} lg={4} className={"leagues-list-league text-center"}>
				<Row className={"leagues-list-league-content"}>
					<Col xs={12} className={"league-short-name"}>
						<NavLink to={`/tabele/${leagueSlug}`} title={`Przejdź na stronę ligi`}>
							<span>{leagueShortName}</span>
						</NavLink>
					</Col>
					<Col xs={12} className={"league-logo"}>
						<NavLink to={`/tabele/${leagueSlug}`} title={`Przejdź na stronę ligi`}>
							<img src={leagueImage || noAvatarImage} alt={leagueShortName}/>
						</NavLink>
					</Col>
					<Col xs={12} className={"league-name"}>
						<NavLink to={`/tabele/${leagueSlug}`} title={`Przejdź na stronę ligi`}>
							<span>{leagueName}</span>
						</NavLink>
					</Col>
				</Row>
			</Col>
		);
		
	});
	
	
	const FILTERED_TEAMS_LIST = TEAMS_LIST && !TEAMS_LIST?.error ? TEAMS_LIST.filter(team => {
		const {team_name: teamName, team_city: teamCity} = team;
		return (
			(teamName || "").toLowerCase().includes(searchForTeamValue.toLowerCase())
			||
			(teamCity || "").toLowerCase().includes(searchForTeamValue.toLowerCase())
		);
	}) : null;
	
	
	const TeamsList = FILTERED_TEAMS_LIST ? FILTERED_TEAMS_LIST.map(team => {
		
		const {
			team_name: teamName, team_short_name: teamShortName, team_city: teamCity, team_slug: teamSlug, team_logo: teamLogo,
			team_leagues_list: teamLeagues
		} = team;
		
		// const teamLeague = teamLeagues ? teamLeagues[0] : null
		
		const {
			league_short_name: leagueShortName, league_slug: leagueSlug
		} = teamLeagues[0] || {};
		
		return (
			<Col key={teamSlug} xs={6} lg={4} className={"teams-list-team text-center"}>
				<Row className={"teams-list-team-content"}>
					<Col xs={12} className={"team-name"}>
						<NavLink to={`/druzyny/${teamSlug}`} title={`Przejdź na stronę druzyny`}>
							<span>{teamName} {teamCity}</span>
						</NavLink>
					</Col>
					<Col xs={12} className={"team-logo"}>
						<NavLink to={`/druzyny/${teamSlug}`} title={`Przejdź na stronę druzyny`}>
							<img src={teamLogo || noAvatarImage} alt={teamShortName}/>
						</NavLink>
					</Col>
					<Col xs={12} className={"team-league"}>
						<NavLink to={`/tabele/${leagueSlug || ""}`} title={`Przejdź na stronę druzyny`} onClick={leagueSlug ? null : e => e.preventDefault()}>
							<span>Liga: {leagueShortName || "-"}</span>
						</NavLink>
					</Col>
				</Row>
			</Col>
		);
		
	}) : null;
	
	
	return (
		<Container fluid={true} id={"results-page-component"} className={"page-container-component"}>
			
			<Container fluid={true} className={"page-component-header-block"}>
				<Container className={"page-component-header-container"}>
					<Row className={"page-component-header-content"}>
						<Col xs={12} as={"h1"} className={"page-component-header"}>
							<span>{`Ligi`}</span>
						</Col>
					</Row>
				</Container>
			</Container>
			
			<Container fluid={true} className={"page-component-content-block leagues-page-leagues-component"}>
				<Container className={"page-component-content-container"}>
					<Row className={"page-component-content"}>
						{LeaguesList}
					</Row>
				</Container>
			</Container>
			
			<Container fluid={true} className={"page-component-header-block"}>
				<Container className={"page-component-header-container"}>
					<Row className={"page-component-header-content"}>
						<Col xs={12} lg={6} as={"h1"} className={"page-component-header"}>
							<span>{`Drużyny`}</span>
						</Col>
						<Col xs={12} lg={6} className={"page-component-header-search"}>
							<Form.Control
								placeholder={"Szukaj drużyny"}
								value={searchForTeamValue}
								onChange={e => setSearchForTeamValue(e.target.value)}
							/>
						</Col>
					</Row>
				</Container>
			</Container>
			
			<Container fluid={true} className={"page-component-content-block leagues-page-teams-component"}>
				<Container className={"page-component-content-container"}>
					<Row className={"page-component-content"}>
						{!!TeamsList?.length && TeamsList}
						{TeamsList?.length === 0 &&
						<Col xs={12} as={Alert} variant={"warning"}>
							<span>{`Brak rezultatów spełniających kryteria`}</span>
						</Col>
						}
					</Row>
				</Container>
			</Container>
			
		</Container>
	);
	
};


export default LeaguesListComponent;
