const API_ROOT_URL = "https://plka-league-admin.webprosdev.usermd.net";
const REST_API_ROOT_URL = `${API_ROOT_URL}/api/v1`;

export const REST_API_URLS = {
	apiRootURL: API_ROOT_URL,
	restApiRootURL: REST_API_ROOT_URL,
	articles: {
		list: {main_url: `${REST_API_ROOT_URL}/articles/list`},
		article: {
			main_url: `${REST_API_ROOT_URL}/articles/list`,
			by_slug_url: `${REST_API_ROOT_URL}/articles/list/article-by-slug`
		}
	},
	pages: {
		contact: {main_url: `${REST_API_ROOT_URL}/content/pages/get-page-by-type/?page_type=contact-page`},
	},
	files: {
		groups: {main_url: `${REST_API_ROOT_URL}/content/files/groups`}
	},
	partners: {
		main_url: `${REST_API_ROOT_URL}/settings/sponsors`,
	},
	socials: {
		main_url: `${REST_API_ROOT_URL}/settings/socials`,
	},
	leagues: {
		seasons: {},
		leagues: {
			list: {main_url: `${REST_API_ROOT_URL}/leagues/leagues`},
			league: {
				main_url: `${REST_API_ROOT_URL}/leagues/leagues`,
				by_slug_url: "${REST_API_ROOT_URL}/leagues/leagues/single-by-slug"
			}
		},
		rounds: {},
		games: {
			results_bar: {
				main_url: `${REST_API_ROOT_URL}/leagues/games/results-bar`,
			}
		}
	},
	teams: {
		list: {main_url: `${REST_API_ROOT_URL}/teams/teams`},
		team: {main_url: `${REST_API_ROOT_URL}/teams/teams`}
	},
	players: {
		list: {main_url: `${REST_API_ROOT_URL}/teams/players`},
		player: {main_url: `${REST_API_ROOT_URL}/teams/players`}
	},
	
};


export default REST_API_URLS;
