import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";


import {Col} from "react-bootstrap";


import StatisticsTableHeaderComponent from "@GamesPages/Players/List/Statistics/Table/Header";
import StatisticsTablePlayersComponent from "@GamesPages/Players/List/Statistics/Table/Players";


export const PlayersListStatisticsTableComponent = ({statisticType, statisticID, statisticName}) => {
	
	
	// const DISPATCHER = useDispatch();
	
	
	const LEAGUE_ID = 42;
	
	const REST_API_URL = process.env.REACT_APP_REST_API_URL;
	const API_LEAGUE_URL = `${REST_API_URL}/leagues/leagues/${LEAGUE_ID}/league_statistics`;
	
	
	const [STATISTIC_PLAYERS_LIST, SET_STATISTIC_PLAYERS_LIST] = useState(null);
	
	
	const GET_PLAYERS_LIST_DATA = async () => {
		// DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const response = await fetch(`${API_LEAGUE_URL}/?results_number=5&player_data=true&sort_type=${statisticID}${statisticType === 1 ? "&last_round=true" : ""}`);
			const responseContent = response.ok ? await response.json() : {error: true, code: response.status};
			SET_STATISTIC_PLAYERS_LIST(responseContent);
		} catch (e) {
			console.log(e);
			// alert("REQUEST ERROR");
		}
		// DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	
	useEffect(() => {
		if (!REST_API_URL) return null;
		GET_PLAYERS_LIST_DATA();
		return () => SET_STATISTIC_PLAYERS_LIST(null);
	}, [statisticType]);
	
	
	return (
		<Col xs={12} lg={4} className={"players-list-statistics-table"}>
			
			<StatisticsTableHeaderComponent statisticName={statisticName} />
			
			<StatisticsTablePlayersComponent playersList={STATISTIC_PLAYERS_LIST} statisticID={statisticID} />
			
		</Col>
	);
	
};


export default PlayersListStatisticsTableComponent;
