import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import {Row, Col} from "react-bootstrap";


import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";


import LeagueTableHeaderComponent from "@GamesPages/Leagues/League/Information/Table/Header";
import LeagueTableListComponent from "@Views/ELements/LeagueElements/League/Table/List";
import REST_API_URLS from "@API/URLS";
const {restApiRootURL} = REST_API_URLS;

export const LeagueTableComponent = ({leagueID, selectedTeam = 0, selectedTeamHandler = null}) => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const REST_API_URL = process.env.REACT_APP_REST_API_URL;
	const REQUESTED_FIELDS = ["league_round_games"].join();
	const API_TABLE_DATA_URL = `${restApiRootURL}/leagues/rounds/?league_round_league=${leagueID}&league_round_type=R&requested_fields=${REQUESTED_FIELDS}`;
	
	
	const [LEAGUE_TABLE_DATA, SET_LEAGUE_TABLE_DATA] = useState(null);
	
	
	const GET_LEAGUE_TABLE_DATA = async () => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const response = await fetch(API_TABLE_DATA_URL);
			const responseContent = response.ok ? await response.json() : {error: true, code: response.status};
			SET_LEAGUE_TABLE_DATA(responseContent);
		} catch (e) {
			// alert("REQUEST ERROR");
			console.log(e);
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	useEffect(() => {
		if (!REST_API_URL || !leagueID) return null;
		GET_LEAGUE_TABLE_DATA();
		return () => SET_LEAGUE_TABLE_DATA(null);
	}, [leagueID]);
	
	
	if (!leagueID) return null;
	
	
	if (LEAGUE_TABLE_DATA?.error) return null;
	
	
	return (
		<Col xs={12} lg={7} className={"league-table-component"}>
			<Row className={"league-table-list"}>
			
				<LeagueTableHeaderComponent />
				
				{!LEAGUE_TABLE_DATA && <SingleContentLoaderComponent />}
				
				{!!LEAGUE_TABLE_DATA &&
				<LeagueTableListComponent
					selectedTeam={selectedTeam}
					selectedTeamHandler={selectedTeamHandler}
					LEAGUE_TABLE_DATA={LEAGUE_TABLE_DATA}
				/>
				}
			
			</Row>
		</Col>
	);
	
};


export default LeagueTableComponent;
