import React from "react";


import {Col, Row} from "react-bootstrap";


export const StatisticsTableHeaderComponent = () => {
	
	
	return (
		<Row className={"statistics-table-header"}>
			<Col xs={12} lg={{span: 10, offset: 2}} className={"statistics-table-data"}>
				<Row className={"statistics-data-table"}>
					<Col xs={2} className={"data-table-cell"}>
						<span>Minuty</span>
					</Col>
					<Col className={"data-table-cell"}>
						<span>2PT</span>
					</Col>
					<Col className={"data-table-cell"}>
						<span>3PT</span>
					</Col>
					<Col className={"data-table-cell"}>
						<span>FT</span>
					</Col>
					<Col className={"data-table-cell"}>
						<span>Zbiórki</span>
					</Col>
					<Col className={"data-table-cell"}>
						<span>Asysty</span>
					</Col>
					<Col className={"data-table-cell"}>
						<span>Bloki</span>
					</Col>
					<Col xs={2} className={"data-table-cell"}>
						<span>Przechwyty</span>
					</Col>
				</Row>
			</Col>
		</Row>
	);
	
};


export default StatisticsTableHeaderComponent;
