import React from "react";

import {useSelector} from "react-redux";


import {Col, Alert} from "react-bootstrap";


import ContainerSectionComponent from "@Elements/Containers/MainSection";


const TEXTS = {
	PL: {not_found_error_message: "Strony nie znalezion"},
	EN: {not_found_error_message: "Page not found"},
};


export const Error404PageComponent = () => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage || "PL";
	
	const {not_found_error_message: notFoundErrorMessage} = TEXTS[ACTIVE_LANGUAGE];
	
	
	return (
		<ContainerSectionComponent id={"error-404-page-component"} classes={"error-page-component"}>
			<Col as={Alert} xs={12} className={"error-message 404-error-message"}>
				<span>{notFoundErrorMessage}</span>
			</Col>
		</ContainerSectionComponent>
	);
	
};


export default Error404PageComponent;
