import React from "react";


import {Col} from "react-bootstrap";

import {FaFacebookF, FaInstagram, FaTwitter, FaYoutube, IoLogoTiktok} from "react-icons/all";


const SOCIALS_ICONS = {
	facebook: FaFacebookF,
	twitter: FaTwitter,
	youtube: FaYoutube,
	instagram: FaInstagram,
	tiktok: IoLogoTiktok
};


export const SocialsItemComponent = ({socialData}) => {
	
	
	if (!socialData) return null;
	
	
	const {
		social_media_active_status: socialActiveStatus,
		social_media_name: socialTitle,
		social_media_type: socialType,
		social_media_url: socialUrl
	} = socialData;
	
	
	const SocialIcon = SOCIALS_ICONS[socialType];
	
	
	if (!socialActiveStatus || !socialUrl) return null;
	
	
	return (
		<Col as={`li`} className={`socials-list-item`}>
			<a href={socialUrl} title={socialTitle} target={`_blank`} rel={`noreferrer noopener`} className={"socials-list-link"}>
				<SocialIcon />
			</a>
		</Col>
	);
	
};


export default SocialsItemComponent;
