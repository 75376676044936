import React, {useEffect, useRef, useState} from "react";

import {useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import REST_API_URLS from "@API/URLS";

import parse from "html-react-parser";


import {Redirect} from "react-router-dom";


import {Col, Container, Row} from "react-bootstrap";


import scrollToDomElement from "@Utilities/Scrolling";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";


const {articles: {article: {by_slug_url: ARTICLE_BY_SLUG_URL}}} = REST_API_URLS;


export const ArticlePageComponent = ({match}) => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const CONTAINER_REF = useRef(null);
	
	
	const [ARTICLE_PAGE_DATA, SET_ARTICLE_PAGE_DATA] = useState(null);
	
	const GET_ARTICLE_PAGE_DATA = async () => {
		const ARTICLE_SLUG = match?.params?.articleSlug;
		if (!ARTICLE_SLUG) return {error: true};
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const response = await fetch(`${ARTICLE_BY_SLUG_URL}/?article_slug=${ARTICLE_SLUG}`);
			console.log(response)
			const content = await response.json();
			SET_ARTICLE_PAGE_DATA(content);
		} catch (e) {
			console.log(e);
			SET_ARTICLE_PAGE_DATA({error: true});
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	useEffect(() => {
		scrollToDomElement(CONTAINER_REF.current, 200)
		if (!ARTICLE_BY_SLUG_URL) return null;
		GET_ARTICLE_PAGE_DATA(ARTICLE_PAGE_DATA);
	}, [ARTICLE_BY_SLUG_URL]);
	
	
	if (!ARTICLE_BY_SLUG_URL || ARTICLE_PAGE_DATA?.error) return <Redirect to={`/404`} />;
	
	
	if (!ARTICLE_PAGE_DATA) return <MainContentLoaderComponent loaderColor={"#17366E"} />;
	
	
	const {
		article_title: articleTitle,
		article_content: articleContent,
		article_image: articleImage,
		article_author: articleAuthor,
		article_published_status: articlePublishedStatus,
		article_date: articleDate
	} = ARTICLE_PAGE_DATA;
	
	
	if (!articlePublishedStatus) return <Redirect to={`/404`} />;
	
	
	return (
		<Container fluid={true} className={"article-page-component"}>
			<Container className={"article-page-container"}>
				<Row>
					
					<Col as={"h1"} xs={12} className={"main-section-header article-header"}>
						<span>{articleTitle}</span>
					</Col>
					
					{!!articleImage &&
					<Col xs={12} className={"article-image"}>
						<img src={articleImage} alt={articleTitle} className={""}/>
					</Col>
					}
					
					<Col xs={12} className={"article-content"}>
						{parse(articleContent)}
					</Col>
					
					<Col xs={12} className={`article-footer`}>
						<span className={`article-author`}>{articleAuthor}</span>
						<span className={`article-date`}>{(new Date(articleDate)).toLocaleDateString()}</span>
					</Col>
					
				</Row>
			</Container>
		</Container>
	);
	
};


export default ArticlePageComponent;
