import React from "react";


import {Col, Row} from "react-bootstrap";


export const PlayerTrophiesAndTitlesComponent = () => {
	
	
	return (
		<Col xs={12} className={"player-trophies-and-titles"}>
			<Row className={"trophies-and-titles-content"}>
				<Col xs={12} lg={4} className={"description-element-header"}>
					<span>Tytuły indywidualne i drużynowe:</span>
				</Col>
				<Col xs={12} lg={8} className={"trophies-and-titles-list"}>
					<span>Brak</span>
				</Col>
			</Row>
		</Col>
	);
	
};


export default PlayerTrophiesAndTitlesComponent;
