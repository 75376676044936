import React from "react";

import {useSelector} from "react-redux";

import REST_API_URLS from "@API/URLS";


import {NavLink} from "react-router-dom";

import {Col} from "react-bootstrap";


const {apiRootURL} = REST_API_URLS;


export const FooterLogoComponent = () => {
	
	
	const APPLICATION_DATA = useSelector(state => state?.data)?.data?.settings;
	
	const {league_name: leagueName, league_logo: leagueLogo} = APPLICATION_DATA || {};
	
	
	return (
		<Col as={`aside`} xs={12} lg={{span: 2, offset: 2}} className={`footer-league-logo`}>
			
			{!!leagueLogo &&
			<NavLink to={"/"} className={"league-logo"}>
				<img src={`${apiRootURL}${leagueLogo.image}`} alt={leagueName?.value || ""}/>
			</NavLink>
			}
			
			{!!leagueName?.value &&
			<span className={"league-name"}>{leagueName.value}</span>
			}
			
		</Col>
	)
	
};


export default FooterLogoComponent;
