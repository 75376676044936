import React from "react";


import ContainerSectionComponent from "@Elements/Containers/MainSection";


import FooterLogoComponent from "@UIParts/Footer/Logo";
import FooterInformationComponent from "@UIParts/Footer/Information";


export const ApplicationFooterComponent = () => {
	
	
	return (
		<ContainerSectionComponent as={"footer"} id={"application-footer-component"}>
			
			<FooterLogoComponent />
			
			<FooterInformationComponent />
		
		</ContainerSectionComponent>
	);
	
};


export default ApplicationFooterComponent;
