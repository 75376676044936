import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import REST_API_URLS from "@API/URLS";


import {Col, Container, Row} from "react-bootstrap";


import ResultsPageDataSwitcherComponent from "@GamesPages/Results/Switcher";
import ResultsPageResultsListComponent from "@GamesPages/Results/Results";


export const LeagueResultsPageComponent = () => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const [selectedLeague, setSelectedLeague] = useState(0);
	const [selectedLeagueRound, setSelectedLeagueRound] = useState(0);
	
	
	const {
		leagues: {leagues: {
			list: {main_url: LEAGUES_LIST_API_URL},
			league: {main_url: SINGLE_LEAGUE_API_URL}
		}},
	} = REST_API_URLS;
	
	
	const [LEAGUES_LIST, SET_LEAGUES_LIST] = useState(null);
	const [ROUNDS_LIST, SET_ROUNDS_LIST] = useState(null);
	
	const GET_LEAGUES_LIST = async () => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const RESPONSE = await fetch(LEAGUES_LIST_API_URL);
			const CONTENT = await RESPONSE.json();
			SET_LEAGUES_LIST(CONTENT);
		} catch (e) {
			console.log(e);
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	const GET_ROUNDS_LIST = async () => {
		if (!selectedLeague) return null;
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const RESPONSE = await fetch(`${SINGLE_LEAGUE_API_URL}/${selectedLeague}/league_rounds/`);
			const CONTENT = await RESPONSE.json();
			const ROUNDS = Object.fromEntries(CONTENT.map(MAP_LEAGUE_ROUNDS));
			SET_ROUNDS_LIST(ROUNDS);
		} catch (e) {
			console.log(e);
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	
	const MAP_LEAGUE_ROUNDS = (leagueRound) => {
		const {league_round_number: leagueRoundNumber} = leagueRound;
		return [leagueRoundNumber, leagueRound];
	};
	
	
	useEffect(() => {
		if (!LEAGUES_LIST_API_URL) return null;
		GET_LEAGUES_LIST();
	}, []);
	
	useEffect(() => {
		if (!SINGLE_LEAGUE_API_URL) return null;
		GET_ROUNDS_LIST();
	}, [selectedLeague]);
	
	
	return (
		<Container fluid={true} id={"results-page-component"} className={"page-container-component"}>
		
			<Container fluid={true} className={"page-component-header-block"}>
				<Container className={"page-component-header-container"}>
					<Row className={"page-component-header-content"}>
						
						<Col xs={12} as={"h1"} className={"page-component-header"}>
							<span>{`Wyniki`}</span>
						</Col>
						
					</Row>
				</Container>
			</Container>
			
			<ResultsPageDataSwitcherComponent
				selectedLeague={selectedLeague}
				selectedLeagueHandler={setSelectedLeague}
				selectedLeagueRound={selectedLeagueRound}
				selectedLeagueRoundHandler={setSelectedLeagueRound}
				LEAGUES_LIST={LEAGUES_LIST}
				LEAGUES_ROUNDS_LIST={ROUNDS_LIST}
			/>
			
			<ResultsPageResultsListComponent
				selectedLeagueRound={selectedLeagueRound}
				LEAGUES_ROUNDS_LIST={ROUNDS_LIST}
			/>
		
		</Container>
	);
	
};


export default LeagueResultsPageComponent;
