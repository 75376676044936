import React from "react";


import {Row, Col} from "react-bootstrap";


import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";


export const NewsArticlesListComponent = ({articlesList = null, activeArticle = null, activeArticleHandler = null}) => {
	
	
	if (!articlesList) return <SingleContentLoaderComponent />;
	
	if (!articlesList?.length) return null;
	
	
	const ArticlesList = articlesList.map(article => {
		const {articleSlug, articleTitle} = article;
		return (
			<Col key={articleSlug} xs={12} lg={3} className={"home-page-article"}>
				<span className={activeArticle === articleSlug ? "active" : ""} onClick={() => activeArticleHandler(articleSlug)}>
					{articleTitle}
				</span>
			</Col>
		);
	});
	
	
	return (
		<Col xs={12} lg={{span: 8, offset: 4}} className={"home-page-articles-list"}>
			<Row className={"home-page-articles-list-content"}>
				{ArticlesList}
			</Row>
		</Col>
	);
	
};


export default NewsArticlesListComponent;
