export const CREATE_LEAGUE_TABLE_FROM_ROUNDS_DATA = (LEAGUE_ROUNDS_DATA) => {
	
	
	if (!LEAGUE_ROUNDS_DATA) return null;
	
	
	const leagueTeams = {};
	
	
	for (let leagueRound of LEAGUE_ROUNDS_DATA) {
	
		const {league_round_games: leagueRoundGames} = leagueRound;
		
		for (let leagueRoundGame of leagueRoundGames) {
		
			const {
				league_game_home_team_data: homeTeamData, league_game_home_team_points: homeTeamPoints,
				league_game_away_team_data: awayTeamData, league_game_away_team_points: awayTeamPoints
			} = leagueRoundGame;
			
			const {team_id: homeTeamID, team_logo: homeTeamLogo, team_name: homeTeamName, team_slug: homeTeamSlug} = homeTeamData;
			const {team_id: awayTeamID, team_logo: awayTeamLogo, team_name: awayTeamName, team_slug: awayTeamSlug} = awayTeamData;
		
			if (!leagueTeams[homeTeamID])
				leagueTeams[homeTeamID] = {teamLogo: homeTeamLogo, teamName: homeTeamName, teamSlug: homeTeamSlug, teamPoints: {I: 0, O: 0}, teamResults: {W: 0, L: 0, D: 0}};
			if (!leagueTeams[awayTeamID])
				leagueTeams[awayTeamID] = {teamLogo: awayTeamLogo, teamName: awayTeamName, teamSlug: awayTeamSlug, teamPoints: {I: 0, O: 0}, teamResults: {W: 0, L: 0, D: 0}};
			
			leagueTeams[homeTeamID].teamPoints.I += homeTeamPoints;
			leagueTeams[homeTeamID].teamPoints.O += awayTeamPoints;
			
			leagueTeams[awayTeamID].teamPoints.I += awayTeamPoints;
			leagueTeams[awayTeamID].teamPoints.O += homeTeamPoints;
			
			if (homeTeamPoints > awayTeamPoints) {
				leagueTeams[homeTeamID].teamResults.W += 1;
				leagueTeams[awayTeamID].teamResults.L += 1;
			} else if (awayTeamPoints > homeTeamPoints) {
				leagueTeams[awayTeamID].teamResults.W += 1;
				leagueTeams[homeTeamID].teamResults.L += 1;
			} else if (awayTeamPoints === homeTeamPoints) {
				leagueTeams[homeTeamID].teamResults.D += 1;
				leagueTeams[awayTeamID].teamResults.D += 1;
			}
			
		}
		
	}
	
	
	const LEAGUE_TEAMS_TABLE = Object.entries(leagueTeams).map(
		([teamID, teamData]) => {return {...teamData, teamID: +teamID}}
	).sort(
		(t1, t2) => {
			const t1Points = t1.teamResults.W * 3 + t1.teamResults.D;
			const t2Points = t2.teamResults.W * 3 + t2.teamResults.D;
			return t1Points === t2Points ? (t1.teamPoints.I > t2.teamPoints.I ? -1 : 1) : t1Points > t2Points ? -1 : 1
		}
	);
	
	
	return LEAGUE_TEAMS_TABLE;
	
	
};


export default CREATE_LEAGUE_TABLE_FROM_ROUNDS_DATA;
