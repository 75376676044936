import React from "react";

import {Redirect, Route, Switch} from "react-router-dom";


import LeaguesListComponent from "@GamesPages/Leagues/List";
import LeaguesLeagueComponent from "@GamesPages/Leagues/League";


export const GamesLeaguesComponent = () => {
	
	
	return (
		<Switch>
			
			<Route path={"/tabele"} exact render={props =>
				<LeaguesListComponent {...props} />
			}
			/>
			
			<Route path={"/tabele/:leagueSlug"} render={props =>
				<LeaguesLeagueComponent {...props} />
			}
			/>
			
			<Redirect to={`/404`} render={(props) => null} />
		
		</Switch>
	);
	
};


export default GamesLeaguesComponent;
