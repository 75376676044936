import React, {useState, useEffect} from "react";

import REST_API_URLS from "@API/URLS";

import {Col} from "react-bootstrap";


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";


import ResultsBarResultComponent from "@UIParts/Results/List/Result";


const {leagues: {games: {results_bar: {main_url: RESULTS_BAR_GAMES_API_URL}}}} = REST_API_URLS;


const SLIDER_SETTINGS = {
	dots: false, arrows: true, infinite: false, speed: 500,
	slidesToShow: 6, slidesToScroll: 1, initialSlide: 0,
	autoplay: false, autoplaySpeed: 4000, pauseOnHover: true,
	responsive: [
		{breakpoint: 1700, settings: {slidesToShow: 5}},
		{breakpoint: 1400, settings: {slidesToShow: 4}},
		{breakpoint: 1200, settings: {slidesToShow: 3}},
		{breakpoint: 992, settings: {slidesToShow: 2}},
		{breakpoint: 768, settings: {slidesToShow: 1}},
		// {breakpoint: 480, settings: {slidesToShow: 1}},
	]
};


export const ResultsBarListComponent = ({gamesType = "finished", xs = 12, lg = 12, ...props}) => {
	
	
	const [GAMES_LIST, SET_GAMES_LIST] = useState(null);
	
	const GET_GAMES_LIST = async () => {
		try {
			const RESPONSE = await fetch(`${RESULTS_BAR_GAMES_API_URL}/?games_type=${gamesType}`);
			const CONTENT = await RESPONSE.json();
			SET_GAMES_LIST(CONTENT);
		} catch (e) {
			console.log(e);
		}
	};
	
	useEffect(() => {
		GET_GAMES_LIST();
		return () => SET_GAMES_LIST(null);
	}, []);
	
	
	
	if (!gamesType || GAMES_LIST?.error) return null;
	
	
	if (!GAMES_LIST) return <SingleContentLoaderComponent xs={xs} lg={lg} />;
	
	
	const notStartedGames = [];
	const liveGames = [];
	const previousGames = [];
	
	for (let game of GAMES_LIST) {
		const {league_game_status: leagueGameStatus} = game;
		if (leagueGameStatus === "LIVE") {
			liveGames.push(game);
		} else if (leagueGameStatus === "NS") {
			notStartedGames.push(game);
		} else {
			previousGames.push(game);
		}
	}
	
	const resultBarGames = [...notStartedGames, ...liveGames, ...previousGames];
	
	
	// SLIDER_SETTINGS.slidesToShow = gamesToShow;
	// SLIDER_SETTINGS.responsive.push({breakpoint: 1024, settings: {slidesToShow: gamesToShow}}) ;
	
	
	const GamesList = resultBarGames.map((game, i) =>
		<ResultsBarResultComponent key={i} gameData={game} gameType={gamesType} />
	);
	
	if (GamesList?.length === 0) {
		return (
			<Col xs={xs} lg={lg} style={{display: "flex", alignItems: "center", justifyContent: "center", border: "2px solid #E5E5E5"}}>
				<span style={{textTransform: "uppercase"}}>Brak aktualnych relacji na żywo</span>
			</Col>
		)
	}
	
	
	return (
		<Col xs={xs} lg={lg} className={"games-results-component"} {...props}>
			<Slider {...SLIDER_SETTINGS} className={"games-results-slider"}>
				{GamesList}
			</Slider>
		</Col>
	);
	
};


export default ResultsBarListComponent;
