import React from "react";


import {Col, Row} from "react-bootstrap";


import SmallDataLoaderComponent from "@UI/Loaders/SmallDataLoader";


export const PlayerStatisticsTableRowComponent = ({rowLabelText = "", rowData = null}) => {
	
	
	if (!rowData) return null;
	
	
	const {
		MIN, PTS2, PTS3, FT, AST, REB, BLK, STL
	} = rowData;
	
	
	return (
		<Row className={"statistics-table-row"}>
			
			{!!rowLabelText &&
			<Col xs={12} lg={2} className={"description-element-header"}>
				<span>{rowLabelText}</span>
			</Col>
			}
			
			<Col xs={12} lg={10} className={"statistics-table-data"}>
				<Row className={"statistics-data-table"}>
					<Col xs={2} className={"data-table-cell"}>
						{MIN === undefined ? <SmallDataLoaderComponent /> : <span>{`${MIN || "-"}`}</span>}
					</Col>
					<Col className={"data-table-cell two-points-throws-data"}>
						{PTS2 === undefined ? <SmallDataLoaderComponent /> : <span>{PTS2 || "-"}</span>}
					</Col>
					<Col className={"data-table-cell three-points-throws-data"}>
						{PTS3 === undefined ? <SmallDataLoaderComponent /> : <span>{PTS3 || "-"}</span>}
					</Col>
					<Col className={"data-table-cell free-throws-data"}>
						{FT === undefined ? <SmallDataLoaderComponent /> : <span>{FT || "-"}</span>}
					</Col>
					<Col className={"data-table-cell rebounds-data"}>
						{REB === undefined ? <SmallDataLoaderComponent /> : <span>{REB || "-"}</span>}
					</Col>
					<Col className={"data-table-cell assists-data"}>
						{AST === undefined ? <SmallDataLoaderComponent /> : <span>{AST || "-"}</span>}
					</Col>
					<Col className={"data-table-cell blocks-data"}>
						{BLK === undefined ? <SmallDataLoaderComponent /> : <span>{BLK || "-"}</span>}
					</Col>
					<Col xs={2} className={"data-table-cell steals-data"}>
						{STL === undefined ? <SmallDataLoaderComponent /> : <span>{STL || "-"}</span>}
					</Col>
				</Row>
			</Col>
			
		</Row>
	);
	
};


export default PlayerStatisticsTableRowComponent;
