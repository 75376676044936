import React from "react";

import ROUTER from "@ROUTER";


import {NavLink} from "react-router-dom";


import {Col, Row} from "react-bootstrap";
import {FaFacebookSquare, FaYoutubeSquare} from "react-icons/all";


import noImage from "@Images/no-avatar.jpg";
import liveIcon from "@Icons/live_icon.png";


export const ResultsBarResultComponent = ({gameData = null}) => {
	
	
	const {
		leaguesPage: {singleLeague: {main_url: LEAGUE_URL}},
		teamsPage: {singleTeam: {main_url: TEAM_URL}}
	} = ROUTER["PL"];
	
	
	
	if (!gameData) return null;
	
	
	const {
		league_game_id: leagueGameID, league_game_status: leagueGameStatus,
		league_game_league_data: {
			// league_name: leagueName, league_slug: leagueSlug
		},
		league_game_round_data: {
			league_round_number: leagueRoundNumber
		},
		league_game_home_team_data: {
			// team_name: homeTeamName,
			team_short_name: homeTeamShortName, team_slug: homeTeamSlug, team_logo: homeTeamLogo
		},
		league_game_home_team_points: homeTeamPoints,
		league_game_away_team_data: {
			// team_name: awayTeamName,
			team_short_name: awayTeamShortName, team_slug: awayTeamSlug, team_logo: awayTeamLogo
		},
		league_game_away_team_points: awayTeamPoints,
		league_game_date: leagueGameDate,
	} = gameData;
	
	// let truncatedHomeTeamName = homeTeamName;
	// let truncatedAwayTeamName = awayTeamName;
	
	// if (homeTeamName.split(" ")[0].length > 8) {
	// 	truncatedHomeTeamName = homeTeamName.split(" ")[0];
	// }
	//
	// if (awayTeamName.split(" ")[0].length > 8) {
	// 	truncatedAwayTeamName = awayTeamName.split(" ")[0];
	// }
	
	const gameDate = new Date(leagueGameDate);
	
	const gameDay = gameDate.getDate();
	const gameMonth = gameDate.getMonth() + 1;
	const gameYear = gameDate.getFullYear() + 1;
	
	const gameHour = gameDate.getHours();
	const gameMinutes = gameDate.getMinutes();
	
	const gameDateDay = `${(gameDay < 10 ? "0" : "") + gameDay}.${(gameMonth < 10 ? "0" : "") + gameMonth}`;
	const gameDateHour = `${(gameHour < 10 ? "0" : "") + gameHour}:${(gameMinutes < 10 ? "0" : "") + gameMinutes}`;
	
	return (
		<Col xs={12} className={"results-bar-item-component" + (leagueGameStatus === "LIVE" ? " live-result" : "")}>
			
			<Row className={"results-bar-item-content"}>
				
				<Col xs={12} className={"results-bar-item-header"}>
					<Row className={"results-bar-item-header-content"}>
						<Col xs={5} className={"league-game-id"}>
							<span className={"league-game-number"}>{`Mecz nr`} {leagueGameID}</span>
							<span className={"league-game-date"}>{gameDateDay}.{gameYear} G. {gameDateHour}</span>
						</Col>
						<Col xs={3} className={"league-game-round"}>
							<span>{`Game`} {leagueRoundNumber}</span>
						</Col>
						<Col xs={4} className={"league-game-streams"}>
							{(leagueGameStatus === "LIVE" || leagueGameStatus === "NS") &&
							<Row className={"league-game-streams-content"}>
								<Col xs={6} className={"league-game-stream"}>
									<a href={`#`} title={`Transmisja`} onClick={e => e.preventDefault()} className={"not-active"}>
										<span>{`Stream`}</span>
									</a>
								</Col>
								<Col xs={6} className={"league-game-socials"}>
									<a href={`#`} title={`Facebook`} onClick={e => e.preventDefault()} className={"not-active"}>
										<FaFacebookSquare />
									</a>
									<a href={`#`} title={`YouTube`} onClick={e => e.preventDefault()} className={"not-active"}>
										<FaYoutubeSquare />
									</a>
								</Col>
							</Row>
							}
						</Col>
					</Row>
				</Col>
				
				<Col xs={12} className={"results-bar-item-data"}>
					<Row className={"results-bar-item-content-content"}>
						
						{leagueGameStatus === "LIVE" && !!liveIcon &&
						<img src={liveIcon} title={"Relacja na żywo"} className={"live-stamp-icon"}/>
						}
						
						<Col xs={{span: 4, offset: 8}} className={"league-game-result-label"}>
							<span>{`Wynik`}</span>
						</Col>
						
						<Col xs={12} className={"league-game-team league-game-home-team"}>
							<Row className={"league-game-team-content league-game-home-team-content"}>
								<Col xs={8} className={"league-game-team-data"}>
									<NavLink to={`${TEAM_URL}/${homeTeamSlug}`} title={`Profil drużyny`} className={"game-team-logo"}>
										<img src={homeTeamLogo || noImage} alt={``}/>
									</NavLink>
									<NavLink to={`${TEAM_URL}/${homeTeamSlug}`} title={`Profil drużyny`} className={"game-team-name"}>
										<span>{homeTeamShortName}</span>
									</NavLink>
								</Col>
								<Col xs={4} className={"league-game-team-points"}>
									<span>{leagueGameStatus === "NS" ? "-" : homeTeamPoints}</span>
								</Col>
							</Row>
						</Col>
						
						<Col xs={12} className={"league-game-team league-game-away-team"}>
							<Row className={"league-game-team-content league-game-away-team-content"}>
								<Col xs={8} className={"league-game-team-data"}>
									<NavLink to={`${TEAM_URL}/${awayTeamSlug}`} title={`Profil drużyny`} className={"game-team-logo"}>
										<img src={awayTeamLogo || noImage} alt={``}/>
									</NavLink>
									<NavLink to={`${TEAM_URL}/${awayTeamSlug}`} title={`Profil drużyny`} className={"game-team-name"}>
										<span>{awayTeamShortName}</span>
									</NavLink>
								</Col>
								<Col xs={4} className={"league-game-team-points"}>
									<span>{leagueGameStatus === "NS" ? "-" : awayTeamPoints}</span>
								</Col>
							</Row>
						</Col>
						
					</Row>
				</Col>
				
				{/*<Col xs={12} className={"results-bar-item-footer"}>*/}
				{/*	<Row className={"results-bar-item-footer-content"}>*/}
				{/*		<Col xs={{span: 4, offset: 8}} className={"item-game-type"}>*/}
				{/*			<span>{`Mecz mistrzowski`}</span>*/}
				{/*		</Col>*/}
				{/*	</Row>*/}
				{/*</Col>*/}
				
			</Row>
			
		</Col>
	);
	
};


export default ResultsBarResultComponent;
