import React from "react";


import {NavLink} from "react-router-dom";


import {Col} from "react-bootstrap";


import ContainerSectionComponent from "@Elements/Containers/MainSection";
import {useSelector} from "react-redux";


const TEXTS = {
	PL: {
		copyrights_word_text: "copyrights",
		developed_by_text: "Developed by"
	}
};

const URLS = {
	PL: {
		privacyPolicyPage: {page_id: null, page_url: "polityka-prywatnosci", page_title: "Polityka Prywatności"},
		regulationsPage: {page_id: null, page_url: "regulamin", page_title: "Regulamin"}
	},
	EN: {
		privacyPolicyPage: {page_id: null, page_url: "", page_title: ""},
		regulationsPage: {page_id: null, page_url: "", page_title: ""}
	}
};


export const ApplicationCopyrightsComponent = () => {
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage || "PL";
	
	const {
		copyrights_word_text: copyrightsWordText, developed_by_text: developedByText
	} = TEXTS[ACTIVE_LANGUAGE];
	
	
	const SystemPagesLinks = Object.entries(URLS[ACTIVE_LANGUAGE]).map(([pageName, pageData]) => {
		const {page_url: pageURL, page_title: pageTitle} = pageData;
		return (
			<NavLink key={pageName} to={`/${pageURL}`} title={pageTitle}>
				<span>{pageTitle}</span>
			</NavLink>
		);
	});
	
	
	return (
		<ContainerSectionComponent as={"aside"} id={"application-copyrights-component"}>
			
			<Col xs={12} lg={4} className={`copyrights-links`}>
				{SystemPagesLinks}
			</Col>
			
			<Col xs={12} lg={4} className={`copyrights-information text-center`}>
				<span className={`copyrights-information-label`}>{copyrightsWordText}</span>
				<span className={`copyrights-information-value`}>{`©${(new Date()).getFullYear()} PLKA`}</span>
			</Col>
			
			<Col xs={12} lg={{span: 3, offset: 1}} className={`copyrights-developers`}>
				<span className={`copyrights-developers-label`}>{developedByText}:</span>
				<a href={`https://webpros.pl`} title={developedByText} target={`_blank`} rel={`noreferrer noopener`}>webpros.pl</a>
			</Col>
		
		</ContainerSectionComponent>
	);
	
};


export default ApplicationCopyrightsComponent;
