import React, {useContext, useState, useEffect} from "react";

import GlobalDataContext from "@Globals";


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";
import ContainerSectionComponent from "@Elements/Containers/MainSection";


import SponsorsSponsorComponent from "@UIParts/Sponsors/Sponsor";


import partner1Logo from "./_Images/partner1.png";
import partner2Logo from "./_Images/partner2.png";
import partner3Logo from "./_Images/partner3.png";
import partner4Logo from "./_Images/partner4.png";
import partner5Logo from "./_Images/partner5.png";
import partner6Logo from "./_Images/partner6.png";
import partner7Logo from "./_Images/partner7.png";


const SPONSORS = [
	{partner_id: 1, partner_name: "", partner_url: "", partner_logo: partner1Logo, partner_active_status: true},
	{partner_id: 1, partner_name: "", partner_url: "", partner_logo: partner2Logo, partner_active_status: true},
	{partner_id: 1, partner_name: "", partner_url: "", partner_logo: partner3Logo, partner_active_status: true},
	{partner_id: 1, partner_name: "", partner_url: "", partner_logo: partner4Logo, partner_active_status: true},
	{partner_id: 1, partner_name: "", partner_url: "", partner_logo: partner5Logo, partner_active_status: true},
	{partner_id: 1, partner_name: "", partner_url: "", partner_logo: partner6Logo, partner_active_status: true},
	{partner_id: 1, partner_name: "", partner_url: "", partner_logo: partner7Logo, partner_active_status: true},
];


export const ApplicationSponsorsComponent = () => {
	
	
	const REST_API_URL = useContext(GlobalDataContext)?.restApiData?.mainRestApiURL;
	
	
	const [sponsorsList, setSponsorsList] = useState(null);
	
	useEffect(() => {
		// if (!REST_API_URL) return null;
		// fetch(`${REST_API_URL}/partners`)
		// 	.then(response => response.ok ? response.json() : [{error: true, code: response.status}])
		// 	.then(resource => setSponsorsList(resource))
		// 	.catch(error => {return {error: true, code: 500, message: error}})
		// ;
		setSponsorsList(SPONSORS);
		return () => {
			setSponsorsList(null);
		};
	}, [REST_API_URL]);
	
	
	if (!sponsorsList) return <MainContentLoaderComponent loaderElementsMargin={10} loaderText={`Sponsorzy`} />;
	
	
	if (sponsorsList.length === 0) return null;
	
	
	const carouselSettings = {
		dots: false, arrows: false, infinite: true, speed: 500,
		slidesToShow: 6, slidesToScroll: 1, initialSlide: 0,
		autoplay: true, autoplaySpeed: 4000, pauseOnHover: true,
		responsive: [
			{breakpoint: 1024, settings: {slidesToShow: 6}},
			{breakpoint: 600, settings: {slidesToShow: 2}},
			{breakpoint: 480, settings: {slidesToShow: 1}}
		]
	};
	
	
	const SponsorsListComponent = sponsorsList.map((sponsor, i) =>
		<SponsorsSponsorComponent key={i} sponsorData={sponsor} />
	);
	
	
	return (
		<ContainerSectionComponent id={`application-sponsors-component`}>
			
			<Slider {...carouselSettings} className={"sponsors-carousel-component"}>
				{SponsorsListComponent}
			</Slider>
		
		</ContainerSectionComponent>
	);
	
};


export default ApplicationSponsorsComponent;
