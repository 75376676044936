import React from "react";


import {Container, Row, Col, Form} from "react-bootstrap";


import noAvatarImage from "@Images/no-avatar.jpg";
import {NavLink} from "react-router-dom";
import PageContainerComponent from "@UI/Containers/PageContainer";
import PageHeaderContainerComponent from "@UI/Containers/HeaderContainer";
import PageContentContainerComponent from "@UI/Containers/ContentContainer";
import TeamsListComponent from "@GamesPages/Teams/List/Teams";
import PlayersListComponent from "@GamesPages/Teams/List/Players";


export const TeamsListPageComponent = () => {
	
	
	return (
		<PageContainerComponent id={"teams-page-component"}>
			
			<PageHeaderContainerComponent headerText={`Drużyny / Zawodnicy`} />
			
			<PageContentContainerComponent cssClasses={"teams-page-content-component"}>
				
				<TeamsListComponent />
				
				<PlayersListComponent />
			
			</PageContentContainerComponent>
		
		</PageContainerComponent>
	);
	
};


export default TeamsListPageComponent;
