import React from "react";


import {Col} from "react-bootstrap";

import {AiFillFileZip, FaFile, FaFileImage, FaFilePdf} from "react-icons/all";


const ICONS = {
	"PDF": FaFilePdf,
	"IMAGE": FaFileImage,
	"ZIP": AiFillFileZip,
	"UNKNOWN": FaFile
};


export const FileComponent = ({fileData = null}) => {
	
	
	if (!fileData) return null;
	
	
	const {
		file_name: fileName,
		file_url: fileURL,
		file_type: fileType,
		file_active_status: fileActiveStatus,
		file_description: fileDescription
	} = fileData;
	
	
	if (!fileActiveStatus) return null;
	
	
	const Icon = ICONS[fileType] || ICONS["UNKNOWN"];
	
	
	return (
		<Col xs={12} className={"files-group-file"}>
			
			<a href={fileURL} title={"Pobierz"} className={"file-download-link"} target={"_blank"}>
				<span className={"file-name"}>{fileName}</span>
				<Icon className={"file-icon"} />
			</a>
			
			{!!fileDescription &&
			<span className={"file-description"}>{fileDescription}</span>
			}
			
		</Col>
	);
	
};


export default FileComponent;
