import React, {useEffect, useState} from "react";

import REST_API_URLS from "@API/URLS";


import {NavLink, Redirect} from "react-router-dom";

import {Col, Form, Row} from "react-bootstrap";


import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";


import noAvatarImage from "@Images/no-avatar.jpg";


const {
	players: {list: {main_url: PLAYERS_LIST_URL}}
} = REST_API_URLS;


export const PlayersListComponent = () => {
	
	
	const [PLAYERS_LIST, SET_PLAYERS_LIST] = useState(null);
	
	const GET_PLAYERS_LIST = async () => {
		try {
			const RESPONSE = await fetch(`${PLAYERS_LIST_URL}/?all_results=true`);
			console.log(RESPONSE)
			const CONTENT = await RESPONSE.json();
			SET_PLAYERS_LIST(CONTENT);
		} catch (e) {
			console.log(e);
		}
	};
	
	useEffect(() => {
		GET_PLAYERS_LIST();
		return () => {};
	}, []);
	
	
	const [playersSearchValue, setPlayersSearchValue] = useState("");
	
	if (!PLAYERS_LIST) return <SingleContentLoaderComponent xs={12} lg={6} />;
	
	
	if (PLAYERS_LIST?.error) return <Redirect to={"/404"} />;
	
	
	const FilteredPlayersList = PLAYERS_LIST.filter(player => {
		const {player_first_name: playerFirstName, player_last_name: playerLastName} = player;
		return (
			playerFirstName?.toLowerCase().includes(playersSearchValue.toLowerCase())
			||
			playerLastName?.toLowerCase().includes(playersSearchValue.toLowerCase())
		)
	}).map(player => {
		const {
			player_first_name: playerFirstName, player_last_name: playerLastName,
			player_slug: playerSlug, player_profile_image: playerImage,
			player_team_data: playerTeamData
		} = player;
		const {team_city: teamCity, team_short_name: teamShortName, team_slug: teamSlug} = playerTeamData || {};
		return (
			<Col key={playerSlug} xs={4} className={"games-elements-list-element players-list-player"}>
				<Row className={"list-element-content"}>
					<Col xs={12} lg={3} className={"player-avatar"}>
						<NavLink to={`/zawodnicy/${playerSlug}`} title={`Przejdź do Profilu Zawodnika`} className={"player-avatar-link"}>
							<img src={playerImage || noAvatarImage} alt={1} className={"player-avatar-image"}/>
						</NavLink>
					</Col>
					<Col xs={12} lg={9} className={"player-name"}>
						<NavLink to={`/zawodnicy/${playerSlug}`} title={`Przejdź do Profilu Zawodnika`} className={"player-name-link"}>
							<span>{playerFirstName} {playerLastName}</span>
						</NavLink>
					</Col>
					<Col xs={12} className={"player-team"}>
						{playerTeamData &&
						<NavLink to={`/druzyny/${teamSlug}`} title={`Przejdź do Profilu Drużyny`} className={"player-team-link"}>
							<span>{teamShortName} {teamCity}</span>
						</NavLink>
						}
						{!playerTeamData &&
						<span>{`Bez drużyny`}</span>
						}
					</Col>
				</Row>
			</Col>
		);
	});
	
	
	return (
		<Col xs={12} lg={6} className={"players-list-component games-elements-list-component"}>
			
			<Row className={"games-elements-list-header-container"}>
				<Col xs={12} as={"h2"} className={"games-elements-list-header"}>
					<span>{`Zawodnicy`}</span>
				</Col>
			</Row>
			
			<Row className={"games-elements-list-search-container"}>
				<Col xs={12} className={"games-elements-list-search"}>
					<Form.Control
						placeholder={`Wyszukaj Zawodnika`}
						value={playersSearchValue}
						onChange={e => setPlayersSearchValue(e.target.value)}
					/>
				</Col>
			</Row>
			
			<Row className={"games-elements-list players-list"}>
				{FilteredPlayersList}
			</Row>
		
		</Col>
	);
	
};


export default PlayersListComponent;
