import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import {Redirect} from "react-router-dom";


import LeagueInformationComponent from "@GamesPages/Leagues/League/Information";
// import LeagueGamesDataComponent from "@GamesPages/Leagues/League/GamesData";
import LeagueHeaderComponent from "@GamesPages/Leagues/League/Header";
import ContainerSectionComponent from "@Elements/Containers/MainSection";
import {Col, Container, Row} from "react-bootstrap";
import REST_API_URLS from "@API/URLS";


const {restApiRootURL} = REST_API_URLS;


export const LeaguesLeagueComponent = ({match}) => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const REST_API_URL = process.env.REACT_APP_REST_API_URL;
	const API_LEAGUE_URL = `${restApiRootURL}/leagues/leagues/single-by-slug`
	
	
	const LEAGUE_SLUG = match?.params?.leagueSlug;
	
	
	const [LEAGUE_DATA, SET_LEAGUE_DATA] = useState(null);
	
	const GET_LEAGUE_DATA = async () => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		console.log(`${API_LEAGUE_URL}/?league_slug=${LEAGUE_SLUG}`)
		try {
			const response = await fetch(`${API_LEAGUE_URL}/?league_slug=${LEAGUE_SLUG}`);
			const responseContent = response.ok ? await response.json() : {error: true, code: response.status};
			SET_LEAGUE_DATA(responseContent);
		} catch (e) {
			// alert("REQUEST ERROR");
			console.log(e);
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	
	useEffect(() => {
		if (!REST_API_URL || !LEAGUE_SLUG) return null;
		GET_LEAGUE_DATA();
		return () => SET_LEAGUE_DATA(null);
	}, [LEAGUE_SLUG]);
	
	
	const [selectedLeagueTeam, setSelectedLeagueTeam] = useState(null);
	
	const changeSelectedLeagueTeam = (teamID) => {
		setSelectedLeagueTeam(teamID === selectedLeagueTeam ? null : teamID);
	};
	
	
	if (!LEAGUE_SLUG || !REST_API_URL) return <Redirect to={`/404`} />;
	
	if (!LEAGUE_DATA || LEAGUE_DATA?.error) return null;
	
	
	const {
		league_id: leagueID, // url: leagueURL,
		// league_status: leagueStatus, league_active_status: leagueActiveStatus,
		league_name: leagueName, // league_short_name: leagueShortName, league_slug: leagueSlug,
		// league_image_url: leagueImageURL, league_description: leagueDescription,
		// league_start_date: leagueStartDate, league_end_date: leagueEndDate,
		// league_season: leagueSeason
	} = LEAGUE_DATA;
	
	
	return (
		<Container fluid={true} id={"league-page-component"} className={"page-container-component"}>
			
			<Container fluid={true} className={"page-component-header-block"}>
				<Container className={"page-component-header-container"}>
					<Row className={"page-component-header-content"}>
						<Col xs={12} as={"h1"} className={"page-component-header"}>
							<span>{leagueName}</span>
						</Col>
					</Row>
				</Container>
			</Container>
			
			<Container fluid={true} className={"page-component-content-block league-page-league-component"}>
				<Container className={"page-component-content-container"}>
					<Row className={"page-component-content"}>
						<LeagueInformationComponent
							leagueData={{leagueID, leagueName}}
							selectedTeam={selectedLeagueTeam}
							selectedTeamHandler={changeSelectedLeagueTeam}
						/>
					</Row>
				</Container>
			</Container>
			
		</Container>
	);
	
};


export default LeaguesLeagueComponent;
