import React, {useEffect, useState} from "react";

import REST_API_URLS from "@API/URLS";


import parse from "html-react-parser";


import {Redirect} from "react-router-dom";

import {Col} from "react-bootstrap";


import MainContentLoaderComponent from "@Elements/Loaders/MainContent";

import PageContentContainerComponent from "@UI/Containers/ContentContainer";


export const ContactPageTextComponent = () => {
	
	
	const {pages: {contact: {main_url: CONTACT_PAGE_API_URL}}} = REST_API_URLS;
	
	
	const [contactPageTextContent, setContactPageTextContent] = useState(null);
	
	const getContactPageTextContent = async () => {
		try {
			const RESPONSE = await fetch(CONTACT_PAGE_API_URL);
			const CONTENT = await RESPONSE.json();
			setContactPageTextContent(CONTENT);
		} catch (e) {
			setContactPageTextContent({error: true, code: 500, message: e});
		}
	};
	
	useEffect(() => {
		getContactPageTextContent();
	}, []);
	
	if (!contactPageTextContent) return <MainContentLoaderComponent />;
	
	
	if (contactPageTextContent?.error) return null;
	
	
	const {
		content_page_active_status: contactPageActiveStatus,
		// content_page_name: contentPageName, content_page_title: contactPageTitle,
		content_page_main_text: contentPageMainText
	} = contactPageTextContent;
	
	
	if (!contactPageActiveStatus) return <Redirect to={"/404"} />;
	
	
	if (!contentPageMainText) return null;
	
	
	return (
		<PageContentContainerComponent cssClasses={"contact-page-text-component"}>
			<Col xs={12} className={"contact-page-text-content"}>
				{parse(contentPageMainText)}
			</Col>
		</PageContentContainerComponent>
	);
	
};


export default ContactPageTextComponent;
