import React from "react";

import INFORMATION from "@DATA/GUI/Information";


import {Col, Row} from "react-bootstrap";


export const LeaguesStatisticsTableHeaderComponent = ({selectedStatisticsType = "TPTS", selectedStatisticsTypeHandler = null}) => {
	
	
	const {statistics: {types: statisticsTypes}} = INFORMATION;
	
	
	const StatisticsTableHeader = Object.entries(statisticsTypes).map(([statisticsTypeName, statisticsTypeData]) => {
		const {label: statisticsTypeLabel} = statisticsTypeData;
		return (
			<Col xs={"auto"} className={"statistics-table-data-type" + (selectedStatisticsType === statisticsTypeName ? " selected" : "")}>
				<span onClick={() => selectedStatisticsTypeHandler(statisticsTypeName)}>{statisticsTypeLabel}</span>
			</Col>
		);
	});
	
	
	return (
		<Row className={"statistics-table-header"}>
			{StatisticsTableHeader}
		</Row>
	);
	
};


export default LeaguesStatisticsTableHeaderComponent;
