import React from "react";


import {Row, Col} from "react-bootstrap";


export const PartnersPartnerComponent = ({partnerData}) => {
	
	
	if (!partnerData) return null;
	
	
	const {
		// partner_id: partnerID,
		sponsor_name: partnerName,
		sponsor_description: partnerDescription,
		sponsor_website: partnerUrl,
		sponsor_logo: partnerLogo,
		sponsor_active_status: partnerActiveStatus
	} = partnerData;
	
	
	if (!partnerActiveStatus) return null;
	
	
	const partnerShortDescription = partnerDescription.length < 300 ? partnerDescription : partnerDescription.slice(0, 300) + '...'
	
	
	return (
		<Col xs={12} md={6} lg={4} className={"partners-list-item"}>
			<Row className={"partner-item-header"}>
				<Col xs={12} className={"partner-title"}>
					<span>{partnerName}</span>
				</Col>
				<Col as={"picture"} xs={12} className={"partner-logo"}>
					<img src={partnerLogo} alt={partnerName} />
				</Col>
			</Row>
			<Row className={"partner-item-content"}>
				<Col xs={12} className={"partner-description"}>
					<p>{partnerShortDescription}</p>
				</Col>
			</Row>
			{partnerUrl &&
			<Row className={"partner-item-footer"}>
				<Col xs={12} className={"partner-www"}>
					<span>{`Strona WWW`}:</span>
					<a href={partnerUrl} title={partnerName} rel="noreferrer noopener" target="_blank">przejdź</a>
				</Col>
			</Row>
			}
		</Col>
	);
	
};


export default PartnersPartnerComponent;
