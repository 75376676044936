import React from "react";


import {Col} from "react-bootstrap";


export const LeagueStatisticsHeaderComponent = ({statisticsType, statisticsTypeHandler}) => {
	
	
	return (
		<Col xs={12} className={"league-statistics-header league-section-header"}>
			<span>Statystyki</span>
			<span className={"statistics-switcher"}>
				<span onClick={() => statisticsTypeHandler(1)} className={statisticsType === 1 ? "selected" : ""}>{`Średnio`}</span>
				<span>{` / `}</span>
				<span onClick={() => statisticsTypeHandler(2)} className={statisticsType === 2 ? "selected" : ""}>{`łącznie`}</span>
			</span>
		</Col>
	);
	
};


export default LeagueStatisticsHeaderComponent;
