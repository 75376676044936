import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";


import {Row, Col} from "react-bootstrap";


import ContainerSectionComponent from "@Elements/Containers/MainSection";


import PlayerDescriptionTextComponent from "@GamesPages/Players/Player/Description/Text";
import PlayerStatisticsTableComponent from "@GamesPages/Players/Player/Description/Table";
import PlayerTrophiesAndTitlesComponent from "@GamesPages/Players/Player/Description/Titles";
import PlayerProgressBarsStatisticsComponent from "@GamesPages/Players/Player/Description/ProgressBars";


import REST_API_URLS from "@API/URLS";


const {restApiRootURL} = REST_API_URLS;


export const PlayerDescriptionComponent = ({playerData}) => {
	
	
	const DISPATCHER = useDispatch();
	console.log(playerData)
	
	const [descriptionContentVisibilityStatus, setDescriptionContentVisibilityStatus] = useState(false);
	
	
	const {
		player_id: playerID, player_description: playerDescription, player_team: {team_league: {league_id: playerLeagueID}}
	} = playerData || {};
	
	
	const REST_API_URL = process.env.REACT_APP_REST_API_URL;
	const API_PLAYER_STATISTICS_URL = `${restApiRootURL}/leagues/leagues/${playerLeagueID}/league_statistics/?player_id=${playerID}`
	
	const [PLAYER_STATISTICS_DATA, SET_PLAYER_STATISTICS_DATA] = useState(null);
	
	console.log(API_PLAYER_STATISTICS_URL)
	const GET_PLAYER_STATISTICS_DATA = async () => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const response = await fetch(`${API_PLAYER_STATISTICS_URL}`);
			const responseContent = response.ok ? await response.json() : {error: true, code: response.status};
			SET_PLAYER_STATISTICS_DATA(responseContent);
		} catch (e) {
			// alert("REQUEST ERROR");
			console.log(e);
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	useEffect(() => {
		if (!REST_API_URL || !playerID) return null;
		GET_PLAYER_STATISTICS_DATA();
		return () => SET_PLAYER_STATISTICS_DATA(null);
	}, [playerID]);
	
	
	if (!playerData) return null;
	
	
	const {
		"2PT": PTS2, "2PTA": PTS2A, "3PT": PTS3, "3PTA": PTS3A, FT: FT, FTA: FTA,
		TREB: REB, AST: AST, STL: STL, BLK: BLK, MIN,
		player_games: GAMES
	} = (PLAYER_STATISTICS_DATA || {"2PT": "-", "3PT": 0, FT: 0});
	
	
	// if (PLAYER_STATISTICS_DATA?.error && PLAYER_STATISTICS_DATA?.code === 404) {
	//
	// }
	
	
	return (
		<ContainerSectionComponent id={"player-details-description-component"}>
		
			<Col xs={12} className={"player-description-component"}>
				<Row className={"description-component-content"}>
					
					<PlayerDescriptionTextComponent
						descriptionContentVisibilityStatus={descriptionContentVisibilityStatus}
						descriptionContentVisibilityStatusHandler={setDescriptionContentVisibilityStatus}
						playerDescription={playerDescription}
					/>
					
					{descriptionContentVisibilityStatus &&
					<PlayerStatisticsTableComponent
						playerData={{
							fullStatistics: {
								MIN: MIN || 0,
								PTS2: PTS2 || 0,
								PTS3: PTS3 || 0,
								FT: FT || 0,
								AST: AST || 0,
								REB: REB || 0,
								BLK: BLK || 0,
								STL: STL || 0
							},
							percentageStatistics: {
								MIN: +(parseFloat(MIN ? MIN.replace(":", ".") : undefined) / GAMES).toFixed(2),
								PTS2: +(PTS2 / GAMES).toFixed(2),
								PTS3: +(PTS3 / GAMES).toFixed(2),
								FT: +(FT / GAMES).toFixed(2),
								AST: +(AST / GAMES).toFixed(2),
								REB: +(REB / GAMES).toFixed(2),
								BLK: +(BLK / GAMES).toFixed(),
								STL: +(STL / GAMES).toFixed(2)
							}
						}}
					/>
					}
					
					{descriptionContentVisibilityStatus &&
					<PlayerTrophiesAndTitlesComponent />
					}
					
					{descriptionContentVisibilityStatus &&
					<PlayerProgressBarsStatisticsComponent playerData={{
						EFF_FG: (+(PTS2 / (PTS2 + PTS2A)) * 100) || 0,
						EFF_3PT: (+(PTS3 / (PTS3 + PTS3A)) * 100) || 0,
						EFF_FT: (+(FT / (FT + FTA)) * 100) || 0
					}} />
					}
					
				</Row>
			</Col>
			
		</ContainerSectionComponent>
	);
	
};


export default PlayerDescriptionComponent;
