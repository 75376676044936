import React from "react";


import {NavLink} from "react-router-dom";

import {Col, Row} from "react-bootstrap";

import {FaFacebookF} from "react-icons/all";


export const TeamDetailsInfoComponent = ({teamData}) => {
	
	
	if (!teamData) return null;
	
	
	const {teamLeagueSlug, teamLeagueName, teamFacebookURL} = teamData;
	
	
	return (
		<Col xs={12} lg={2} className={"team-information"}>
			<Row className={"team-information-content"}>
				<Col xs={10} lg={12} className={"team-league"}>
					<span className={"team-league-label"}>{`Liga:`}</span>
					<NavLink to={`/ligi/${teamLeagueSlug}`} title={teamLeagueName}>
						<span>{teamLeagueName}</span>
					</NavLink>
				</Col>
				<Col xs={2} lg={12} className={"team-facebook"}>
					<a href={teamFacebookURL} title={"Facebook"} onClick={e => e.preventDefault()}>
						<FaFacebookF />
					</a>
				</Col>
			</Row>
		</Col>
	);
	
};


export default TeamDetailsInfoComponent;
