import React from "react";


import {Col, Row} from "react-bootstrap";

import {NavLink} from "react-router-dom";


import noAvatarImage from "@Images/no-avatar.jpg";


export const TableListTeamComponent = ({teamData = null}) => {
	
	
	if (!teamData) return null;
	
	
	const {
		teamTablePlace, teamSlug,
		teamName, teamLogo,
		teamResults
	} = teamData;
	
	const teamTablePointsNumber = 2 * teamResults.W + 1 * teamResults.L;
	
	
	return (
		<Row className={"league-table-team-content"}>
			
			<Col xs={1} className={"table-team-place"}>
				<span>{teamTablePlace}</span>
			</Col>
			
			<Col xs={1} className={"table-team-logo"}>
				<NavLink to={`/druzyny/${teamSlug}`} title={teamName}>
					<img src={teamLogo || noAvatarImage} alt={teamName} />
				</NavLink>
			</Col>
			
			<Col xs={6} lg={7} className={"table-team-name"}>
				<NavLink to={`/druzyny/${teamSlug}`} title={teamName}>
					<span>{teamName}</span>
				</NavLink>
			</Col>
			
			<Col xs={2} lg={1} className={"table-team-games"}>
				<span>{teamResults.W}-{teamResults.L}</span>
			</Col>
			
			<Col xs={2} className={"table-team-points"}>
				<span>{teamTablePointsNumber} pkt</span>
			</Col>
			
		</Row>
	);
	
};


export default TableListTeamComponent;
