import React from "react";

import {Col, Row} from "react-bootstrap";


export const StatisticsTableHeaderComponent = ({selectedStatisticsType, selectedStatisticsTypeHandler}) => {
	
	
	return (
		<Row className={"statistics-table-header"}>
			
			<Row className={"statistics-table-header-mask"}>
			
				<Col
					className={"statistics-table-header-data statistics-table-header-points" + (selectedStatisticsType === "TPTS" ? " selected" : "")}
					onClick={() => selectedStatisticsTypeHandler("TPTS")}
				>
					<span>Punkty</span>
				</Col>
				
				<Col
					className={"statistics-table-header-data statistics-table-header-eval" + (selectedStatisticsType === "EVAL" ? " selected" : "")}
					onClick={() => selectedStatisticsTypeHandler("EVAL")}
				>
					<span>Eval</span>
				</Col>
				
				<Col
					className={"statistics-table-header-data statistics-table-header-assists" + (selectedStatisticsType === "AST" ? " selected" : "")}
					onClick={() => selectedStatisticsTypeHandler("AST")}
				>
					<span>Asysty</span>
				</Col>
				
				<Col
					className={"statistics-table-header-data statistics-table-header-rebounds" + (selectedStatisticsType === "TREB" ? " selected" : "")}
					onClick={() => selectedStatisticsTypeHandler("TREB")}
				>
					<span>Zbiórki</span>
				</Col>
				
				<Col
					className={"statistics-table-header-data statistics-table-header-steals" + (selectedStatisticsType === "STL" ? " selected" : "")}
					onClick={() => selectedStatisticsTypeHandler("STL")}
				>
					<span>Przechwyty</span>
				</Col>
				
				<Col
					className={"statistics-table-header-data statistics-table-header-blocks" + (selectedStatisticsType === "BLK" ? " selected" : "")}
					onClick={() => selectedStatisticsTypeHandler("BLK")}
				>
					<span>Bloki</span>
				</Col>
			
			</Row>
			
		</Row>
	);
	
};


export default StatisticsTableHeaderComponent;
