import React from "react";


import {Col, Row} from "react-bootstrap";


import noAvatarImage from "@Images/no-avatar.jpg";


export const TeamDetailsLogoComponent = ({teamData}) => {
	
	
	if (!teamData) return null;
	
	
	const {teamLogo, teamName} = teamData;
	
	
	return (
		<Col xs={3} lg={3} className={"team-logo"}>
			<Row as={"picture"} className={"team-logo-image"}>
				<img src={teamLogo || noAvatarImage} alt={teamName}/>
			</Row>
		</Col>
	);
	
};


export default TeamDetailsLogoComponent;
