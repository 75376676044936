export const INFORMATION = {
	PLKA_URL: "https://plka-project.webprosdev.usermd.net",
	statistics: {
		types: {
			"TPTS": {label: "Punkty"},
			"EVAL": {label: "EVAL"},
			"AST": {label: "Asysty"},
			"TREB": {label: "Zbiórki"},
			"STL": {label: "Przechwyty"},
			"BLK": {label: "Bloki"},
		}
	}
};


export default INFORMATION;
