import React from "react";


import {Col} from "react-bootstrap";


import {RiseLoader} from "react-spinners";


import ContainerSectionComponent from "@Elements/Containers/MainSection";


export const MainContentLoaderComponent = ({
	loaderText = `Loading...`, loaderSize = 15, loaderElementsMargin = 2, loaderColor = "#1D4288",
	forwardRef = null
}) => {

	
	return (
		<ContainerSectionComponent classes={"main-content-loader main-content-loader-component"} ref={forwardRef}>
			
			{loaderText &&
			<Col xs={12} className="loader-text" style={{marginBottom: 50, textAlign: "center", color: loaderColor}}>
				<span>{loaderText}</span>
			</Col>
			}
			
			<Col xs={12} className="loader-animation" style={{textAlign: "center"}}>
				<RiseLoader
					size={loaderSize}
					margin={loaderElementsMargin}
					color={loaderColor}
				/>
			</Col>
			
		</ContainerSectionComponent>

	);
	
};


export default MainContentLoaderComponent;
