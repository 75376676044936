import React from "react";

import {useDispatch, useSelector} from "react-redux";
import {CHANGE_GLOBAL_BLACKOUT_STATUS_ACTION} from "@Actions";


import {NavLink} from "react-router-dom";


import {Col} from "react-bootstrap";


import HeaderNavigationTeamsLinkComponent from "@UIParts/Header/Navigation/Teams";


export const HeaderNavigationLinkComponent = ({linkData, listDisplayHandler}) => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage || "PL";
	
	const {
		page_id: pageID,
		page_url: pageURL, page_title: pageTitle,
		// page_type: pageType,
		page_css: pageCSS
	} = linkData;
	
	
	const linkURL = pageURL[ACTIVE_LANGUAGE];
	const linkTitle = pageTitle[ACTIVE_LANGUAGE];
	
	const pageClickHandler = () => {
		listDisplayHandler(false);
		DISPATCHER(CHANGE_GLOBAL_BLACKOUT_STATUS_ACTION(false));
	};
	
	
	// if (pageID === 3) return <HeaderNavigationTeamsLinkComponent listDisplayHandler={listDisplayHandler} />;
	
	
	return (
		<Col as={"li"} className={"navigation-list-item" + (pageCSS ? ` ${pageCSS}` : "")}>
			<NavLink to={`/${linkURL}`} title={linkTitle} onClick={pageClickHandler} className={"navigation-list-link"}>
				<span>{linkTitle}</span>
			</NavLink>
		</Col>
	);
	
};


export default HeaderNavigationLinkComponent;
