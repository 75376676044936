import React from "react";


import {Container, Row} from "react-bootstrap";


import ResultsListComponent from "@GamesPages/Results/Results/List";


export const ResultsPageResultsListComponent = ({selectedLeagueRound = null, LEAGUES_ROUNDS_LIST = null}) => {
	
	
	if (!selectedLeagueRound) return <></>
	
	
	const {
		league_round_number: leagueRoundNumber, league_round_games: leagueRoundGames
	} = selectedLeagueRound;
	
	const nextToSelectedRound = LEAGUES_ROUNDS_LIST[leagueRoundNumber + 1];
	let nextToSelectedRoundGames = null;
	if (nextToSelectedRound) {
		const {league_round_games: leagueRoundGames} = nextToSelectedRound;
		nextToSelectedRoundGames = leagueRoundGames;
	}
	
	return (
		<Container fluid={true} className={"page-component-content-block results-page-results-component"}>
			<Container className={"page-component-content-container"}>
				<Row className={"page-component-content"}>
					
					<ResultsListComponent
						leagueRoundNumber={leagueRoundNumber}
						leagueRoundGames={leagueRoundGames}
					/>
					
					{!!nextToSelectedRound &&
					<ResultsListComponent
						leagueRoundNumber={leagueRoundNumber + 1}
						leagueRoundGames={nextToSelectedRoundGames}
					/>
					}
					
				</Row>
			</Container>
		</Container>
	);
};


export default ResultsPageResultsListComponent;
