import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {CHANGE_REST_API_REQUEST_STATUS_ACTION} from "@Actions";

import REST_API_URLS from "@API/URLS";


import scrollToDomElement from "@Utilities/Scrolling";


import DefaultWarningMessageComponent from "@UI/Messages/Default/Warning";

import MainContentLoaderComponent from "@Elements/Loaders/MainContent";

import PageContainerComponent from "@UI/Containers/PageContainer";
import PageHeaderContainerComponent from "@UI/Containers/HeaderContainer";
import PageContentContainerComponent from "@UI/Containers/ContentContainer";


import PartnersPartnerComponent from "@CommonPages/PartnersPage/Partner";


const {partners: {main_url: PARTNERS_PAGE_URL}} = REST_API_URLS;


const TEXTS = {
	PL: {
		header_text: "Partnerzy",
		no_files_message_text: "Brak Partnerów"
	}
};


export const PartnersPageComponent = () => {
	
	
	const DISPATCHER = useDispatch();
	
	
	const PAGE_CONTAINER_REF = useRef(null);
	
	useEffect(() => {
		if (!PAGE_CONTAINER_REF.current) return null;
		scrollToDomElement(PAGE_CONTAINER_REF.current, 250);
	}, []);
	
	
	const ACTIVE_LANGUAGE = useSelector(state => state?.localization)?.activeLanguage || "PL";
	
	const {header_text: headerText, no_files_message_text: noPartnersMessageText} = TEXTS[ACTIVE_LANGUAGE];

	
	const [PARTNERS_LIST, SET_PARTNERS_LIST] = useState(null);
	
	
	const GET_PARTNERS_LIST = async () => {
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(true));
		try {
			const response = await fetch(PARTNERS_PAGE_URL);
			const content = await response.json();
			SET_PARTNERS_LIST(content);
		} catch (e) {
			console.log(e);
			SET_PARTNERS_LIST({error: true});
		}
		DISPATCHER(CHANGE_REST_API_REQUEST_STATUS_ACTION(false));
	};
	
	useEffect(() => {
		if (!PARTNERS_PAGE_URL) return null;
		GET_PARTNERS_LIST();
	}, []);
	
	
	if (!PARTNERS_LIST) return <MainContentLoaderComponent />;
	
	
	if (PARTNERS_LIST.error) return null;
	
	
	const PartnersList = PARTNERS_LIST.map((partner, i) =>
		<PartnersPartnerComponent key={i} partnerData={partner} />
	);
	
	
	return (
		<PageContainerComponent forwardRef={PAGE_CONTAINER_REF} id={"partners-page-component"}>
		
			<PageHeaderContainerComponent headerText={headerText} />
		
			<PageContentContainerComponent>
				{PartnersList.length > 0 ?
					PartnersList
					:
					<DefaultWarningMessageComponent messageText={noPartnersMessageText} />
				}
			</PageContentContainerComponent>
			
		</PageContainerComponent>
	);
	
};


export default PartnersPageComponent;
