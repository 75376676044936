import React from "react";


import {Col} from "react-bootstrap";

import {PuffLoader} from "react-spinners";


export const SmallDataLoaderComponent = ({xs = 12, sm = null, lg = 12, xl = null, size = 30, color = "#17366E"}) => {
	
	
	return (
		<Col xs={xs} sm={sm || xs} lg={lg} xl={xl || lg} style={{padding: 0}} className={"small-loader-component"}>
			<PuffLoader color={color} size={size} />
		</Col>
	)
	
};


export default SmallDataLoaderComponent;
