import React from "react";


import {Col, Row} from "react-bootstrap";


import FileComponent from "@CommonPages/DownloadPage/File";


export const FilesGroupComponent = ({filesGroupData = null}) => {
	
	
	if (!filesGroupData) return null;
	
	
	const {
		file_group_name: fileGroupName,
		file_group_active_status: fileGroupActiveStatus,
		file_group_files: fileGroupFiles
	} = filesGroupData;
	
	
	if (!fileGroupActiveStatus || !fileGroupFiles) return null;
	
	
	const FilesList = fileGroupFiles.map((file, i) =>
		<FileComponent key={i} fileData={file} />
	);
	
	
	return (
		<Col xs={12} className={"files-group-component"}>
			<Row className={"files-group-content"}>
				
				{fileGroupName &&
				<Col xs={12} className={"files-group-header"}>
					<span>{fileGroupName}</span>
				</Col>
				}
				
				{FilesList}
				
			</Row>
		</Col>
	);
	
};


export default FilesGroupComponent;
