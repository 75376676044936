import React from "react";


import {Col} from "react-bootstrap";


export const LeagueTableHeaderComponent = () => {
	
	
	return (
		<Col xs={12} className={"league-table-header league-section-header"}>
			<span>Tabela</span>
		</Col>
	);
	
};


export default LeagueTableHeaderComponent;
