import React from "react";

import {Redirect, Route, Switch} from "react-router-dom";


import GamesPlayersListComponent from "@GamesPages/Players/List";
import PlayerDetailsComponent from "@GamesPages/Players/Player";


export const GamesPlayersComponent = () => {
	
	
	return (
		<Switch>
			
			<Route path={"/zawodnicy"} exact render={props =>
				<GamesPlayersListComponent {...props} />
			}
			/>
			
			<Route path={"/zawodnicy/:playerSlug"} render={props =>
				<PlayerDetailsComponent {...props} />
			}
			/>
			
			<Redirect to={`/404`} render={(props) => null} />
		
		</Switch>
	);
	
};


export default GamesPlayersComponent;
