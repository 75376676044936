import React from "react";


import {Col, Row} from "react-bootstrap";

import {NavLink} from "react-router-dom";


import SingleContentLoaderComponent from "@Elements/Loaders/SingleContent";


export const StatisticsTablePlayersComponent = ({statisticID, playersList = null}) => {
	
	
	if (!playersList) return <Row><SingleContentLoaderComponent /></Row>;
	
	
	if (playersList?.error) return null;
	
	
	const PlayersList = playersList.map((player, i) => {
		const {player_data: {
			player_slug: playerSlug, player_first_name: playerFirstName, player_last_name: playerLastName
		}} = player;
		return (
			<Col xs={12} className={"statistics-table-list-player" + (i === 0 ? " first" : "")}>
				<Row className={"list-player-content"}>
					<Col xs={1} className={"player-ranking"}>
						<span>{i + 1}.</span>
					</Col>
					<Col xs={8} lg={9} className={"player-name"}>
						<NavLink to={`/zawodnicy/${playerSlug}`}>
							{playerFirstName} {playerLastName}
						</NavLink>
					</Col>
					<Col xs={2} className={"player-results"}>
						<span>{player[statisticID]}</span>
					</Col>
				</Row>
			</Col>
		);
	});
	
	
	return (
		<Row className={"statistics-table-list"}>
			{PlayersList}
		</Row>
	);

};


export default StatisticsTablePlayersComponent;
