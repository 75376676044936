import React from "react";

import {Redirect, Route, Switch} from "react-router-dom";


import GamesLeaguesComponent from "@GamesPages/Leagues";
import GamesTeamsComponent from "@GamesPages/Teams";
import GamesPlayersComponent from "@GamesPages/Players";


export const ApplicationGamesComponent = () => {
	
	
	return (
		<Switch>
			
			<Route path={"/ligi"} render={props =>
				<GamesLeaguesComponent {...props} />
			}
			/>
			
			<Route path={"/druzyny"} render={props =>
				<GamesTeamsComponent />
			}
			/>
			
			<Route path={"/zawodnicy"} render={props =>
				<GamesPlayersComponent {...props} />
			}
			/>
			
			<Redirect to={"/"} />
			
		</Switch>
	);
	
};


export default ApplicationGamesComponent;
