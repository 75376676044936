import React, {useEffect, useState} from "react";


import {Col, Row} from "react-bootstrap";

import {AiFillCaretDown} from "react-icons/all";


export const ResultsPageRoundsSwitcherComponent = ({
	selectedLeague,
	selectedLeagueRound, selectedLeagueRoundHandler, leagueRounds
}) => {
	
	
	const [roundsListOpenStatus, setRoundsListOpenStatus] = useState(false);
	
	const changeSelectedRoundHandler = (leagueID) => {
		setRoundsListOpenStatus(false);
		selectedLeagueRoundHandler(leagueID);
	};
	
	
	useEffect(() => {
		if (!leagueRounds || leagueRounds?.length === 0) return null;
		let SELECTED_ROUND;
		if (leagueRounds?.length === 1) {
			SELECTED_ROUND = Object.values(leagueRounds[0]);
		} else {
			SELECTED_ROUND = getClosestRoundDate(Object.values(leagueRounds));
		}
		selectedLeagueRoundHandler(SELECTED_ROUND);
	}, [selectedLeague, leagueRounds]);
	
	const getClosestRoundDate = (leagueRounds) => {
		const today = new Date();
		const closestToToday = leagueRounds.reduce(
			(round1, round2) => {
				const {league_round_start: leagueRound1Start, league_round_end: leagueRound1End} = round1;
				const {league_round_start: leagueRound2Start, league_round_end: leagueRound2End} = round2;
				return (
					new Date(leagueRound1Start) - today < new Date(leagueRound2Start) - today
					||
					new Date(leagueRound1End) - today < new Date(leagueRound2End) - today
						? round1 : round2
				);
			}
		);
		return closestToToday;
	};
	
	
	if (!selectedLeague || !selectedLeagueRound || leagueRounds?.length === 0) return null;
	
	
	const {league_round_number: leagueRoundNumber} = selectedLeagueRound;
	
	
	const LeagueRoundsList = Object.values(leagueRounds).map(round => {
		const {league_round_number: leagueRoundNumber} = round;
		return (
			<Row key={leagueRoundNumber} className={"options-list-option"} onClick={() => changeSelectedRoundHandler(leagueRounds[leagueRoundNumber])}>
				<Col xs={12} className={"options-list-option-name"}>
					<span>{leagueRoundNumber} {`Kolejka`}</span>
				</Col>
			</Row>
		);
	});
	
	
	return (
		<Col xs={6} lg={4} className={"rounds-switcher-component switcher-option-component"}>
			<Row className={"rounds-switcher-component-content"}>
				
				<Col xs={12} className={"rounds-switcher-active-option switcher-active-option"} onClick={() => setRoundsListOpenStatus(!roundsListOpenStatus)}>
					<span className={"switcher-active-option-label"}>{`Kolejka`}: {leagueRoundNumber}</span>
					<span className={"switcher-active-option-icon"}>
						<AiFillCaretDown />
					</span>
				</Col>
				
				{LeagueRoundsList?.length && roundsListOpenStatus &&
				<Col xs={12} className={"switcher-options-list"}>
					{LeagueRoundsList}
				</Col>
				}
			
			</Row>
		</Col>
	);
	
};


export default ResultsPageRoundsSwitcherComponent;
