import React from "react";


import {Container, Row} from "react-bootstrap";


import ResultsBarListComponent from "@UIParts/Results/List";


export const ApplicationResultsBarComponent = () => {
	
	
	return (
		<Container fluid={true} className={"results-bar-component"}>
			<Row className={"results-bar-content"}>
				
				<ResultsBarListComponent gamesType={"finished"} xs={12} />
				
			</Row>
		</Container>
	);
};


export default ApplicationResultsBarComponent;
